const DATE_FORMAT_M_DD_YYYY = 'M/DD/yyyy';
const DATE_FORMAT_DDD_M_D = 'ddd M/D';
const DATE_FORMAT_DD_MMM_YYYY = 'DD MMM, yyyy';
const DATE_FORMAT_DD_MM_YYYY = 'DD/MM/yyyy';
const DATE_FORMAT_DD_MMMM_YYYY = 'DD MMMM, yyyy';
const DATE_FORMAT_DD_MMM = 'DD MMM';
const DATE_FORMAT_MMM_YY = 'MMM YY';
const DATE_FORMAT_FULL = 'M/DD/yyyy @ hh:mm a';
const DATE_FORMAT_MONTH = 'MMMM';
const DATE_FORMAT_YYYY_M_DD = 'yyyy-MM-DD';
const DATE_FORMAT_YYYY = 'yyyy';

const dateFormats = {
  DATE_FORMAT_M_DD_YYYY,
  DATE_FORMAT_DDD_M_D,
  DATE_FORMAT_DD_MMM_YYYY,
  DATE_FORMAT_DD_MMM,
  DATE_FORMAT_MMM_YY,
  DATE_FORMAT_FULL,
  DATE_FORMAT_DD_MMMM_YYYY,
  DATE_FORMAT_MONTH,
  DATE_FORMAT_YYYY_M_DD,
  DATE_FORMAT_DD_MM_YYYY,
  DATE_FORMAT_YYYY
};

export default dateFormats;
