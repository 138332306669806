import { useEffect, useCallback, useState } from 'react';

import { useFetchCustomer } from 'services/data/customers';

import { ICustomer } from 'models/customer';

interface ICustomerResult {
  customer: ICustomer | null;
}

const useCustomer = (customerID?: string): ICustomerResult => {
  const [customer, setCustomer] = useState<ICustomer | null>(null);
  const fetchCustomer = useFetchCustomer();

  const getCustomer = useCallback(
    async (customerID: string) => {
      setCustomer(await fetchCustomer(customerID));
    },
    [fetchCustomer]
  );

  useEffect(() => {
    if (customerID) {
      getCustomer(customerID);
    } else {
      setCustomer(null);
    }
  }, [getCustomer, customerID]);

  return {
    customer
  };
};

const customersHooks = {
  useCustomer
};

export default customersHooks;
