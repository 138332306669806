import { Map, GeoJSONSource, AnyLayer } from 'mapbox-gl';
import * as turf from '@turf/turf';
import WKT from 'terraformer-wkt-parser';

import { ISubTract } from 'models/sub-tract';
import { TEventHandler } from 'models/map';

import {
  getMapSourceById,
  addLayerToMap,
  reorderLayers,
  LAYERS_PRIORITY,
  SUB_TRACTS_LAYERS,
  SUB_TRACTS_TEXT_LAYERS,
  SUB_TRACTS_HOVERED_LAYERS,
  SUB_TRACTS_SELECTED_LAYERS
  // getPolygonFeature
} from 'services/map/mapHelpers';

const SELECTED_TRACTS_SOURCE = 'selected-sub-tracts';
const HOVERED_TRACT_SOURCE = 'hovered-sub-tract';

const borderWidth = {
  base: 1,
  stops: [
    [13, 1.4],
    [16, 4]
  ]
};

const selectedTractPaint = {
  'line-color': '#ffffbb',
  'line-width': 6
};

export const addSubTractsLayers = (map: Map): void => {
  const sources = ['sub-tracts', 'sub-tracts-center', HOVERED_TRACT_SOURCE, SELECTED_TRACTS_SOURCE];

  sources.forEach((source) => {
    const mapSource = getMapSourceById(map, source);

    if (!mapSource) {
      map.addSource(source, {
        data: turf.featureCollection([]),
        type: 'geojson'
      });
    }
  });

  addLayerToMap(map, SUB_TRACTS_LAYERS[0], {
    id: SUB_TRACTS_LAYERS[0],
    type: 'line',
    source: 'sub-tracts',
    paint: {
      'line-color': '#ffffe0',
      'line-width': borderWidth
    }
  } as AnyLayer);

  addLayerToMap(map, SUB_TRACTS_LAYERS[1], {
    id: SUB_TRACTS_LAYERS[1],
    type: 'fill',
    source: 'sub-tracts',
    paint: {
      'fill-opacity': 0.5,
      'fill-color': 'transparent'
    }
  });

  addLayerToMap(map, SUB_TRACTS_HOVERED_LAYERS[1], {
    id: SUB_TRACTS_HOVERED_LAYERS[1],
    type: 'line',
    source: HOVERED_TRACT_SOURCE,
    paint: {
      'line-color': '#ffffbb',
      'line-width': 6
    }
  });

  addLayerToMap(map, SUB_TRACTS_TEXT_LAYERS[0], {
    id: SUB_TRACTS_TEXT_LAYERS[0],
    type: 'symbol',
    source: 'sub-tracts-center',
    paint: {
      'text-opacity': {
        base: 1,
        stops: [
          [11.4, 0.2],
          [12.2, 1]
        ]
      },
      'text-color': '#306bc9',
      'text-halo-color': '#ffffff',
      'text-halo-width': 12
    },
    layout: {
      'text-allow-overlap': false,
      'text-font': ['Roboto Regular'],
      'text-line-height': 1.5,
      'text-transform': 'uppercase',
      'text-size': {
        stops: [
          [11.4, 4],
          [14, 20]
        ]
      },
      'text-field': '{sub_business_unit_name}'
    }
  });

  addLayerToMap(map, SUB_TRACTS_SELECTED_LAYERS[0], {
    id: SUB_TRACTS_SELECTED_LAYERS[0],
    type: 'line',
    source: SELECTED_TRACTS_SOURCE,
    paint: selectedTractPaint
  });

  reorderLayers(
    map,
    LAYERS_PRIORITY.map((layerName, index) => ({
      zIndex: index,
      name: layerName
    }))
  );
};

export const drawSelectedSubTracts = (map: Map, subTracts: ISubTract[], selectedTracts: string[] = []) => {
  const selectedTractsFeatures = subTracts
    .filter((tract: ISubTract) => selectedTracts.includes(tract.id))
    .map((tract: ISubTract) => {
      const geometry = WKT.parse(tract.WKT);
      return turf.feature(geometry, tract);
    })
    .filter((feature) => !!feature) as any as turf.Feature<turf.Polygon, ISubTract>[];
  const selectedTractsSource = map.getSource(SELECTED_TRACTS_SOURCE) as GeoJSONSource;
  selectedTractsSource.setData(turf.featureCollection(selectedTractsFeatures));
};

export const drawSubTracts = (map: Map, subTracts: ISubTract[]) => {
  const subTractsFeatures = subTracts
    .map((entry: ISubTract) => {
      const geometry = WKT.parse(entry.WKT);

      return turf.feature(geometry, entry);
    })
    .filter((feature) => !!feature) as any as turf.Feature<turf.Polygon, ISubTract>[];
  const centerPoints = subTractsFeatures.map((entry) => {
    const center = turf.centerOfMass(entry, { properties: entry.properties });

    if (turf.inside(center, entry)) {
      return center;
    }

    const point = turf.pointOnFeature(entry);
    point.properties = entry.properties;

    return point;
  });

  const subTractsSource = map.getSource('sub-tracts') as GeoJSONSource;
  const subTractsCenterSource = map.getSource('sub-tracts-center') as GeoJSONSource;

  subTractsSource.setData(turf.featureCollection(subTractsFeatures));
  subTractsCenterSource.setData(turf.featureCollection(centerPoints));
};

export const setTractEventHandlers = (map: Map, tractClickHandler: TEventHandler, tractMousemoveHandler?: TEventHandler, tractMouseleaveHandler?: TEventHandler): void => {
  map.on('click', SUB_TRACTS_LAYERS[1], tractClickHandler);
  if (tractMousemoveHandler) {
    map.on('mousemove', SUB_TRACTS_LAYERS[1], tractMousemoveHandler);
  }
  if (tractMouseleaveHandler) {
    map.on('mouseleave', SUB_TRACTS_LAYERS[1], tractMouseleaveHandler);
  }
};

export const removeTractEventHandlers = (map: Map, tractClickHandler: TEventHandler, tractMousemoveHandler?: TEventHandler, tractMouseleaveHandler?: TEventHandler): void => {
  map.off('click', SUB_TRACTS_LAYERS[1], tractClickHandler);

  if (tractMousemoveHandler) {
    map.off('mousemove', SUB_TRACTS_LAYERS[1], tractMousemoveHandler);
  }
  if (tractMouseleaveHandler) {
    map.off('mouseleave', SUB_TRACTS_LAYERS[1], tractMouseleaveHandler);
  }
};

export const drawHoveredSubTract = (map: Map, subTract: ISubTract) => {
  const geometry = WKT.parse(subTract.WKT);
  const polygon = turf.feature(geometry, { active: true });
  (map.getSource(HOVERED_TRACT_SOURCE) as GeoJSONSource).setData(turf.featureCollection([polygon]) as any);
  map.getCanvas().style.cursor = 'pointer';
};

export const clearHoveredSubTract = (map: Map) => {
  (map.getSource(HOVERED_TRACT_SOURCE) as GeoJSONSource).setData(turf.featureCollection([]));
  map.getCanvas().style.cursor = '';
};
