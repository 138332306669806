import { useEffect, useCallback, useState, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useFetchSurveys } from 'services/data/surveys';

import { ISurvey } from 'models/survey';

interface ISurveysResult {
  surveys: ISurvey[];
}

const useSurveys = (projectID?: string): ISurveysResult => {
  const [surveys, setSurveys] = useState<ISurvey[]>([]);
  const fetchSurveys = useFetchSurveys();

  const getSurveys = useCallback(
    async (projectID: string) => {
      setSurveys(await fetchSurveys(projectID));
    },
    [fetchSurveys]
  );

  useEffect(() => {
    if (projectID) {
      getSurveys(projectID);
    } else {
      setSurveys([]);
    }
  }, [getSurveys, projectID]);

  return {
    surveys
  };
};

interface ISelectedSurveyResult {
  selectedSurvey: ISurvey | null;
  setSelectedSurvey: (survey: ISurvey) => void;
}

const useSelectedSurvey = (surveys: ISurvey[]): ISelectedSurveyResult => {
  const [searchParams, setSearchParams] = useSearchParams();
  const surveyID = searchParams.get('surveyID');
  const selectedSurveyRef = useRef<ISurvey | null>(null);
  const selectedSurvey = useMemo(() => {
    if (!surveyID && selectedSurveyRef.current) return selectedSurveyRef.current;
    const sortedSurveys = surveys.filter((entry) => entry.surveyName === 'GT1').sort((a, b) => (a.endDate > b.endDate ? 1 : -1));
    return sortedSurveys.find((entry) => entry.id === surveyID) || sortedSurveys[sortedSurveys.length - 1] || null;
  }, [surveyID, surveys]);

  useEffect(() => {
    selectedSurveyRef.current = selectedSurvey;
  }, [selectedSurvey]);

  const setSelectedSurvey = useCallback(
    (survey: ISurvey) => {
      setSearchParams({ surveyID: survey.id });
    },
    [setSearchParams]
  );

  useEffect(() => {
    if (!surveyID && selectedSurvey) {
      setSearchParams({ surveyID: selectedSurvey.id });
    }
  }, [surveyID, selectedSurvey, setSearchParams]);

  return {
    selectedSurvey,
    setSelectedSurvey
  };
};

const surveysHooks = {
  useSurveys,
  useSelectedSurvey
};

export default surveysHooks;
