import numeral from 'numeral';

const normalizeDecimal = (value: number, maxDecimals = 3): number => {
  const decimalPart = value % 1;
  const decimalsToShow = decimalPart.toFixed(maxDecimals);

  if (!decimalPart || +decimalsToShow === 0) {
    // eslint-disable-next-line no-bitwise
    return value | 0;
  }

  const roundMultiplier = 10 ** maxDecimals;

  return Math.round(value * roundMultiplier) / roundMultiplier;
};

const convertToHighestOrder = (value: number): string => (value >= 1000000 ? `${normalizeDecimal(value / 1000000, 2)} M` : value.toString());

const convertToPercentage = (number: number): number => number * 100;

const formatNumber = (number: number, format = '0,0') => numeral(number).format(format);

const formatShortFormNumber = (value: number, maxDecimals = 0): string => {
  if (value >= 10 ** 6) {
    return `${normalizeDecimal(value / 10 ** 6, 2)} M`;
  } else if (value >= 10 ** 4) {
    return `${normalizeDecimal(value / 10 ** 3, 2)} K`;
  }
  return normalizeDecimal(value, maxDecimals).toLocaleString();
};

const numberUtils = {
  normalizeDecimal,
  convertToHighestOrder,
  formatShortFormNumber,
  convertToPercentage,
  formatNumber
};

export default numberUtils;
