import { IUser } from 'models/user';

export enum EEventType {
  SignIn = 'signIn',
  SignOut = 'signOut'
}

export interface ISignInEventParams {
  type: EEventType.SignIn;
  params: Pick<IUser, 'email'>;
}

export interface ISignOutEventParams {
  type: EEventType.SignOut;
  params: Pick<IUser, 'email'>;
}

export type TEventParams = ISignInEventParams | ISignOutEventParams;
