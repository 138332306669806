import React, { useState, useMemo, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { IProject } from 'models/project';
import { ITract } from 'models/tract';

import SearchBar from 'atomicComponents/SearchBar';
import BreadcrumbDropdownContent, { IListModel, ESuggestionModelType } from 'components/main/MainPageBreadcrumbs/BreadcrumbDropdownContent';
import SearchResultsDropdownContent from 'components/main/ProjectSearch/SearchResultsDropdownContent';

const SearchWrapper = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin: 0 0 16px;
`;

const Search = styled.div`
  margin: 0;
  height: 34px;
  width: 100%;
`;

interface IProps {
  project: IProject;
  tract: ITract | null;
  projects: IProject[];
  tracts: ITract[];
  prefix?: string;
}

const filterListModels = (models: IListModel[], searchText: string): IListModel[] => {
  const result = models.filter((entry) => entry.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1).sort((a, b) => a.name.localeCompare(b.name));
  return result;
};

const TractSearch = ({ project, projects, tracts, tract, prefix = '' }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [isSearchExpanded, setIsSearchExpanded] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [showSuggestions, setShowSuggestions] = useState(false);

  const defaultSuggestions = useMemo(() => {
    let result: IListModel[] = projects.map((entry) => ({ ...entry, name: entry.project_name }));
    if (tract) {
      result = tracts.map((entry) => ({ ...entry, name: entry.business_unit_name }));
    }
    return result.filter((model) => !!model.name).sort((a, b) => a.name.localeCompare(b.name));
  }, [tract, projects, tracts]);

  const defaultSuggestionsModelType = useMemo(() => {
    if (tract) return ESuggestionModelType.Tract;
    return ESuggestionModelType.Project;
  }, [tract]);

  const projectsSuggestions = useMemo(() => {
    const result = filterListModels(
      projects.map((entry) => ({ ...entry, name: entry.project_name })),
      searchText
    );
    return result;
  }, [projects, searchText]);

  const tractsSuggestions = useMemo(() => {
    const result = filterListModels(
      tracts.map((entry) => ({ ...entry, name: entry.business_unit_name })),
      searchText
    );
    return result;
  }, [tracts, searchText]);

  const linkPrefix = useMemo(() => {
    if (tract) {
      return `${prefix}/${project.id}/`;
    }
    return `${prefix}/`;
  }, [project.id, tract, prefix]);

  const getTitle = useMemo(() => {
    if (isSearchExpanded) {
      return '';
    }
    if (!project?.project_name) return t('shared.all');
    return tract?.business_unit_name || project?.project_name;
  }, [t, isSearchExpanded, tract?.business_unit_name, project?.project_name]);

  const handleListEnd = useCallback((direction: -1 | 1): void => {
    if (wrapperRef.current && direction === -1) {
      wrapperRef.current.querySelector('input')?.focus();
    }
  }, []);

  const handleGoToSuggestions = useCallback((): void => {
    if (wrapperRef.current) {
      wrapperRef.current.querySelector('button')?.focus();
    }
  }, []);

  return (
    <SearchWrapper ref={wrapperRef}>
      <Search>
        <SearchBar
          title={getTitle}
          suggestions={
            !searchText ? (
              <BreadcrumbDropdownContent onListEnd={handleListEnd} models={defaultSuggestions} modelType={defaultSuggestionsModelType} linkPrefix={linkPrefix} />
            ) : (
              <SearchResultsDropdownContent linkPrefix={`${prefix}`} projects={projectsSuggestions} tracts={tractsSuggestions} onListEnd={handleListEnd} />
            )
          }
          onSearchChange={setSearchText}
          onGoToSuggestions={handleGoToSuggestions}
          onExpandedStateChange={setIsSearchExpanded}
          showSuggestions={showSuggestions}
          setShowSuggestions={setShowSuggestions}
          name="search"
        />
      </Search>
    </SearchWrapper>
  );
};

export default TractSearch;
