import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { useArrowNavigationListener } from 'hooks/util.hooks';

import { mfColors } from 'vars';

const DropdownEntries = styled.div`
  max-height: 450px;
  overflow: auto;
  margin: 0 -4px;
`;

const DropdownLink = styled(NavLink)`
  text-decoration: none;
  color: ${mfColors.darkGrey};
`;

const DropdownEntry = styled.button`
  min-width: 120px;
  width: 100%;
  padding: 6px 4px;
  font-size: 12px;
  font-weight: normal;
  display: block;
  color: ${mfColors.darkGrey};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 4px;
  border: 0;
  text-align: left;

  &:focus,
  &:active {
    outline: 0;
    background-color: ${mfColors.lightBlue};
    color: ${mfColors.white};
    font-family: MontserratBold;
  }
`;

export enum ESuggestionModelType {
  Customer,
  Project,
  Tract
}

export interface IListModel {
  id: string | null;
  name: string;
}

interface IProps {
  linkPrefix: string;
  models: IListModel[];
  modelType: ESuggestionModelType;
  onListEnd?: (direction: -1 | 1) => void;
  onEntryClicked?: (entry: IListModel, type: ESuggestionModelType, e: React.MouseEvent<HTMLElement>) => void;
}

const BreadcrumbDropdownContent = ({ models, modelType, onListEnd, linkPrefix, onEntryClicked }: IProps): JSX.Element => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const handleArrowKeys = useArrowNavigationListener(containerRef, 'button', onListEnd);

  return (
    <DropdownEntries onKeyDown={handleArrowKeys} ref={containerRef}>
      {models.map((model, index) => (
        <DropdownLink key={model.id} to={`${linkPrefix}${model.id}`}>
          <DropdownEntry tabIndex={0} onClick={(e: React.MouseEvent<HTMLElement>) => onEntryClicked && onEntryClicked(model, modelType, e)}>
            {model.name}
          </DropdownEntry>
        </DropdownLink>
      ))}
    </DropdownEntries>
  );
};

export default BreadcrumbDropdownContent;
