import { Map, GeoJSONSource } from 'mapbox-gl';
import * as turf from '@turf/turf';

import { reorderLayers, getMapSourceById, PATCH_FILL, PATCH_BORDER, PATCH_SELECTED, PATCH_HOVERED, LAYERS_PRIORITY } from 'services/map/mapHelpers';

const PATCH_SOURCE_NAME = 'patches';
const PATCH_SOURCE_SELECTED_NAME = 'patches-selected';
const PATCH_SOURCE_HOVERED_NAME = 'patches-hovered';

const getPatchFeature = (patch: { geometry: any }) => {
  const feature = turf.feature(patch.geometry, patch);
  return feature;
};

const borderWidth = {
  base: 1,
  stops: [
    [13, 1],
    [16, 2]
  ]
};

const borderWidthSelected = {
  base: 3,
  stops: [
    [13, 3],
    [16, 5]
  ]
};

const drawPatches = (map: Map, patches): void => {
  if (!map.getSource(PATCH_SOURCE_NAME)) {
    map.addSource(PATCH_SOURCE_NAME, {
      data: turf.featureCollection([]),
      type: 'geojson'
    });
  }

  if (!map.getLayer(PATCH_BORDER)) {
    map.addLayer({
      id: PATCH_BORDER,
      type: 'line',
      source: PATCH_SOURCE_NAME,
      paint: {
        'line-color': '#ffffe0',
        'line-width': borderWidth
      }
    });
  }

  if (!map.getSource(PATCH_SOURCE_SELECTED_NAME)) {
    map.addSource(PATCH_SOURCE_SELECTED_NAME, {
      data: turf.featureCollection([]),
      type: 'geojson'
    });
  }

  if (!map.getLayer(PATCH_SELECTED)) {
    map.addLayer({
      id: PATCH_SELECTED,
      type: 'line',
      source: PATCH_SOURCE_SELECTED_NAME,
      paint: {
        'line-color': '#ffffe0',
        'line-width': borderWidthSelected
      }
    });
  }

  if (!map.getSource(PATCH_SOURCE_HOVERED_NAME)) {
    map.addSource(PATCH_SOURCE_HOVERED_NAME, {
      data: turf.featureCollection([]),
      type: 'geojson'
    });
  }

  if (!map.getLayer(PATCH_HOVERED)) {
    map.addLayer({
      id: PATCH_HOVERED,
      type: 'line',
      source: PATCH_SOURCE_HOVERED_NAME,
      paint: {
        'line-color': '#ffffe0',
        'line-width': borderWidthSelected
      }
    });
  }

  const hasPatchWithMetric = !!patches.find((patch) => !!patch.tCO2eRelative);

  if (!map.getLayer(PATCH_FILL)) {
    map.addLayer({
      id: PATCH_FILL,
      type: 'fill',
      source: PATCH_SOURCE_NAME,
      paint: {
        'fill-color': hasPatchWithMetric ? ['step', ['get', 'tCO2eRelative'], '#A02C2D', 15, '#C7534F', 27, '#CD7F27', 52, '#D5B02D', 71, '#A2BD42', 93, '#76A747'] : 'transparent'
      }
    });
  }

  reorderLayers(
    map,
    LAYERS_PRIORITY.map((layer, index) => ({
      zIndex: index,
      name: layer
    }))
  );

  const patchFeatures = patches.map((patch) => getPatchFeature(patch));
  const collection = turf.featureCollection(patchFeatures) as any;
  const source = getMapSourceById(map, PATCH_SOURCE_NAME);

  if (source) {
    (source as GeoJSONSource).setData(collection);
  }
};

const removePatches = (map: Map): void => {
  if (map.getLayer(PATCH_FILL)) {
    map.removeLayer(PATCH_FILL);
  }
  if (map.getLayer(PATCH_BORDER)) {
    map.removeLayer(PATCH_BORDER);
  }
  if (map.getSource(PATCH_SOURCE_NAME)) {
    map.removeSource(PATCH_SOURCE_NAME);
  }
  if (map.getLayer(PATCH_SELECTED)) {
    map.removeLayer(PATCH_SELECTED);
  }
  if (map.getSource(PATCH_SOURCE_SELECTED_NAME)) {
    map.removeSource(PATCH_SOURCE_SELECTED_NAME);
  }
  if (map.getLayer(PATCH_HOVERED)) {
    map.removeLayer(PATCH_HOVERED);
  }
  if (map.getSource(PATCH_SOURCE_HOVERED_NAME)) {
    map.removeSource(PATCH_SOURCE_HOVERED_NAME);
  }
};

export type TEventHandler = (event?: any) => void;

export const setPatchEventHandlers = (map: Map, pointHandler: TEventHandler, mouseMoveHandler: TEventHandler, mouseLeaveHandler: TEventHandler): void => {
  map.on('click', PATCH_FILL, pointHandler);
  map.on('mousemove', PATCH_FILL, mouseMoveHandler);
  map.on('mouseleave', PATCH_FILL, mouseLeaveHandler);
};

export const removePatchEventHandlers = (map: Map, pointHandler: TEventHandler, mouseMoveHandler: TEventHandler, mouseLeaveHandler: TEventHandler): void => {
  map.off('click', PATCH_FILL, pointHandler);
  map.off('mousemove', PATCH_FILL, mouseMoveHandler);
  map.off('mouseleave', PATCH_FILL, mouseLeaveHandler);
};

export const drawHoveredPatchData = (map: Map, data, cursor = 'pointer') => {
  const hoveredSource = map.getSource(PATCH_SOURCE_HOVERED_NAME) as GeoJSONSource;
  hoveredSource.setData(turf.featureCollection(data));
  map.getCanvas().style.cursor = cursor;
};

export const drawSelectedPatchData = (map: Map, data) => {
  const selectedSource = map.getSource(PATCH_SOURCE_SELECTED_NAME) as GeoJSONSource;
  selectedSource.setData(turf.featureCollection(data));
};

const mapHeatmapService = {
  drawPatches,
  removePatches,
  setPatchEventHandlers,
  removePatchEventHandlers,
  drawHoveredPatchData,
  drawSelectedPatchData
};

export default mapHeatmapService;
