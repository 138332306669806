import { Map, AnyLayer, GeoJSONSource, AnySourceImpl, MapMouseEvent, MapboxGeoJSONFeature } from 'mapbox-gl';
import * as turf from '@turf/turf';

export const PATCH_FILL = 'patch-fill';
export const PATCH_BORDER = 'patch-border';
export const PATCH_SELECTED = 'patch-selected';
export const PATCH_HOVERED = 'patch-hovered';

export const PATCHES_LAYERS = [PATCH_FILL, PATCH_BORDER];

export const SUB_TRACTS_LAYERS = ['sub-tracts-line', 'sub-tracts-fill'];

export const SUB_TRACTS_SELECTED_LAYERS = ['sub-tracts-lines-selected'];

export const SUB_TRACTS_HOVERED_LAYERS = ['sub-tracts-fill-hovered', 'sub-tracts-line-hovered'];

export const SUB_TRACTS_TEXT_LAYERS = ['sub-tracts-text'];

export const TRACTS_LAYERS = ['tract-marker', 'tract-text'];

export const LAYERS_PRIORITY = [
  ...TRACTS_LAYERS,
  ...PATCHES_LAYERS,
  ...SUB_TRACTS_LAYERS,
  SUB_TRACTS_HOVERED_LAYERS[0],
  SUB_TRACTS_HOVERED_LAYERS[1],
  ...SUB_TRACTS_SELECTED_LAYERS,
  ...SUB_TRACTS_TEXT_LAYERS,
  PATCH_SELECTED,
  PATCH_HOVERED
];

export type TStrokePaintType = 'categorical' | 'identity' | 'exponential' | 'interval';

export type TMapMouseEvent = MapMouseEvent & { features?: MapboxGeoJSONFeature[] | undefined };

export const getMapSourceById = (map: Map, id: string): AnySourceImpl | null => map.getSource(id);

export const getMapLayerById = (map: Map, id: string) => map.getLayer(id);

export const removeMapLayerById = (map: Map, id: string): void => {
  if (getMapLayerById(map, id)) {
    map.removeLayer(id);
  }
};

export const removeMapSourceById = (map: Map, id: string): void => {
  if (getMapSourceById(map, id)) {
    map.removeSource(id);
  }
};

export const addLayerToMap = (mapbox: Map, layerID: string, options: AnyLayer): void => {
  const layer = getMapLayerById(mapbox, layerID);

  if (layer) return;

  mapbox.addLayer(options);
};

export const addMapSource = (mapbox: Map, id: string, data: any) => {
  if (!mapbox) return;

  const source = getMapSourceById(mapbox, id) as GeoJSONSource;
  const collection = turf.featureCollection(data) as any;

  if (source) {
    source.setData(collection);
  } else {
    mapbox.addSource(id, {
      type: 'geojson',
      data: collection
    });
  }
};

interface IReorderedLayer {
  name: string;
  zIndex: number;
}

export const reorderLayers = (mapbox: Map, layers: IReorderedLayer[]) => {
  layers
    .filter((layer: IReorderedLayer) => mapbox.getLayer(layer.name))
    .sort((current: IReorderedLayer, next: IReorderedLayer) => current.zIndex - next.zIndex)
    .forEach((layer: IReorderedLayer) => {
      mapbox.moveLayer(layer.name);
    });
};

export interface IWrappedTileType {
  tiles: string[];
}

export interface IWrappedURLType {
  url: string;
}

export const loadImage = async (mapbox: Map, imagePath: string, name: string): Promise<void> => {
  if (mapbox.hasImage(name)) return Promise.resolve();

  return new Promise((resolve, reject) => {
    mapbox.loadImage(imagePath, (err, image) => {
      if (err) {
        return reject(err);
      }

      if (image && !mapbox.hasImage(name)) {
        mapbox.addImage(name, image);
      }

      return resolve();
    });
  });
};

export const getPolygonFeature = <T>(geometry: turf.Position[][], feature: T): turf.Feature<turf.Polygon, T> | null => {
  try {
    return turf.polygon(geometry, feature);
  } catch {
    return null;
  }
};

export type TPaintMatcher = ['get', string];
export type TPaintValue = ['match' | 'step', TPaintMatcher, ...(string | number)[]];
