import { Map, SymbolLayer } from 'mapbox-gl';
import * as turf from '@turf/turf';

import { TRACTS_LAYERS, LAYERS_PRIORITY, addLayerToMap, reorderLayers, addMapSource, loadImage } from 'services/map/mapHelpers';

import { ITract } from 'models/tract';

const TRACT_MARKER_SOURCE = 'tract-marker-source';

const TRACT_MARKER_LAYER_CONFIG = {
  id: TRACTS_LAYERS[0],
  type: 'symbol',
  source: TRACT_MARKER_SOURCE,
  layout: {
    'icon-image': ['step', ['get', 'tCO2e_per_acre'], 'tract_worst', 15, 'tract_bad', 27, 'tract_average', 52, 'tract_average_high', 71, 'tract_good', 93, 'tract_best'],
    'icon-allow-overlap': true,
    'icon-size': {
      base: 2,
      stops: [
        [16, 0.5],
        [19, 1],
        [24, 2]
      ]
    },
    'icon-offset': {
      stops: [
        [16, [0, -25]],
        [17, [0, -40]],
        [20, [0, -50]]
      ]
    }
  }
};

const TRACT_TEXT_LAYER_CONFIG = {
  id: TRACTS_LAYERS[1],
  type: 'symbol',
  source: TRACT_MARKER_SOURCE,
  minzoom: 5,
  paint: {
    'text-color': '#fff',
    'text-halo-color': ['step', ['get', 'tCO2e_per_acre'], '#A02C2D', 15, '#C7534F', 27, '#CD7F27', 52, '#D5B02D', 71, '#A2BD42', 93, '#76A747'],
    'text-halo-width': 12
  },
  layout: {
    'text-allow-overlap': false,
    'text-font': ['Roboto Bold'],
    'text-offset': [0, 1],
    'text-size': {
      stops: [
        [8, 14],
        [18, 28]
      ]
    },
    'text-field': '{name}'
  }
};

export const addTractLayers = async (map: Map) => {
  await loadImage(map, '/images/tract-worst.png', 'tract_worst');
  await loadImage(map, '/images/tract_bad.png', 'tract_bad');
  await loadImage(map, '/images/tract_good.png', 'tract_good');
  await loadImage(map, '/images/tract_average.png', 'tract_average');
  await loadImage(map, '/images/tract_average_high.png', 'tract_average_high');
  await loadImage(map, '/images/tract_best.png', 'tract_best');

  addMapSource(map, TRACT_MARKER_SOURCE, []);
  addLayerToMap(map, TRACTS_LAYERS[0], TRACT_MARKER_LAYER_CONFIG as SymbolLayer);
  addLayerToMap(map, TRACTS_LAYERS[1], TRACT_TEXT_LAYER_CONFIG as SymbolLayer);

  TRACTS_LAYERS.forEach((layer) => {
    map.on('mousemove', layer, () => {
      map.getCanvas().style.cursor = 'pointer';
    });

    map.on('mouseout', layer, () => {
      map.getCanvas().style.cursor = '';
    });
  });

  reorderLayers(
    map,
    LAYERS_PRIORITY.map((layer, index) => ({
      zIndex: index,
      name: layer
    }))
  );
};

const addClickHandlers = (map: Map, clickHandler: (event) => void): void => {
  TRACTS_LAYERS.forEach((layer: string) => {
    map.on('click', layer, clickHandler);
  });
};

const removeClickHandlers = (map: Map, clickHandler: (event) => void): void => {
  TRACTS_LAYERS.forEach((layer: string) => {
    map.off('click', layer, clickHandler);
  });
};

const getTractClusters = (tracts) => {
  const clusters = tracts.map((tract) => turf.feature(JSON.parse(tract.geometry), tract));

  return clusters;
};

const getTractCenter = (cluster) => {
  const center = turf.centroid(cluster);

  if (cluster.properties.id && center.properties) {
    center.properties.id = cluster.properties.id;
    center.properties.tCO2e_per_acre = cluster.properties.tCO2e_per_acre;
    center.properties.name = cluster.properties.business_unit_name;
  }

  return center;
};

export const drawTracts = (map: Map, tracts: ITract[], clickHandler: (event) => void): void => {
  const clusters = getTractClusters(tracts);
  const tractMarkers = clusters.map(getTractCenter);
  addMapSource(map, TRACT_MARKER_SOURCE, tractMarkers);

  addClickHandlers(map, clickHandler);
};

export const clearTracts = (map: Map, clickHandler?: () => void) => {
  [TRACT_MARKER_SOURCE].forEach((item) => {
    addMapSource(map, item, []);
  });

  if (clickHandler) {
    removeClickHandlers(map, clickHandler);
  }
};
