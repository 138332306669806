import { useCallback } from 'react';
import { useRollbar } from '@rollbar/react';

import { IUser } from 'models/user';

import appConfig from 'config/config.json';

export enum EStorageType {
  Firestore = 'Firestore',
  Firebase = 'Firebase',
  Api = 'API',
  Storage = 'Storage'
}

export enum ELogLevel {
  Critical = 'critical',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Debug = 'debug'
}

export type TLoggerFunction = (e: Error, context?: string, storageType?: EStorageType, level?: ELogLevel) => void;

interface ILoggerResult {
  logError: TLoggerFunction;
  setUser: (user: IUser) => void;
}

const useLogger = (): ILoggerResult => {
  const rollbar = useRollbar();

  const logError = useCallback(
    (e: Error, context = '', storageType = EStorageType.Firestore, level = ELogLevel.Error) => {
      rollbar[level]('Data request errored', e, {
        storageType,
        context
      });
    },
    [rollbar]
  );

  const setUser = useCallback(
    (user: IUser) => {
      rollbar.configure({
        payload: {
          ...appConfig.rollbar.payload,
          person: {
            id: user.uid,
            username: user.displayName || ''
          },
          userAgent: navigator.userAgent
        }
      });
    },
    [rollbar]
  );

  return {
    logError,
    setUser
  };
};

export default useLogger;
