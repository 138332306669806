import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ITractMetric } from 'models/tract-metric';
import { IProjectMetrics } from 'models/project';
import { ITract } from 'models/tract';

import numberUtils from 'utils/numbers';

import { mfColors } from 'vars';

const Wrapper = styled.div`
  margin: 0 12px 0 0;
  padding: 12px 0 12px 16px;
  max-height: 320px;
  overflow: auto;
  position: relative;
`;

interface IInfoProps {
  isExpanded: boolean;
}

const getMaxHeight = ({ isExpanded }: IInfoProps) => {
  if (!isExpanded) return '132px';
  return '280px';
};

const Info = styled.div<IInfoProps>`
  max-height: ${getMaxHeight};
  transition: max-height 0.5s;
  overflow: hidden;
  padding-right: 16px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 12px;
  position: relative;
  line-height: 24px;
`;

const GrowInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  padding: 0 4px;
  border-radius: 4px;
  font-size: 12px;

  &:last-child {
    margin: 0 0 16px;
  }

  &:nth-child(odd) {
    background-color: ${mfColors.lightGreyBlue50};
  }
`;

const Label = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Value = styled.div`
  font-family: MontserratBold;
`;

const ShowMore = styled.div`
  cursor: pointer;
  font-size: 12px;
  line-height: 15px;
  color: ${mfColors.lightBlue};
  text-align: right;
  padding-right: 16px;
`;

interface IProps {
  metric: ITractMetric | IProjectMetrics | null;
  tract: ITract | null;
}

const AreaMetric = ({ metric, tract }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  if (!metric) {
    return <></>;
  }

  return (
    <Wrapper>
      <Info isExpanded={isExpanded}>
        <Row>
          <Label>{t('carbon.tCO2e')}</Label>
          <Value>{numberUtils.normalizeDecimal(metric.tCO2e, 0).toLocaleString()}</Value>
        </Row>
        <Row>
          <Label>{t('carbon.AGB')}</Label>
          <Value>{numberUtils.normalizeDecimal(metric.AGB, 0).toLocaleString()}</Value>
        </Row>
        <Row>
          <Label>{t('carbon.tCO2e_acre')}</Label>
          <Value>{numberUtils.normalizeDecimal(metric.tCO2e_per_acre, 2).toLocaleString()}</Value>
        </Row>
        <Row>
          <Label>{t('carbon.area_acres')}</Label>
          <Value>{numberUtils.normalizeDecimal(metric.area, 0).toLocaleString()}</Value>
        </Row>
        <GrowInfoRow>
          <Label>{t('carbon.tree_cover')}</Label>
          <Value>{`${numberUtils.normalizeDecimal(metric.tree_coverage_percent, 2)}%`}</Value>
        </GrowInfoRow>
        <GrowInfoRow>
          <Label>{t('carbon.mean_tree_height')}</Label>
          <Value>{numberUtils.normalizeDecimal(metric.avg_tree_height, 1)}</Value>
        </GrowInfoRow>
      </Info>
      <ShowMore onClick={() => setIsExpanded((prev) => !prev)}>{t(isExpanded ? 'shared.less_information' : 'shared.more_information')}</ShowMore>
    </Wrapper>
  );
};

export default AreaMetric;
