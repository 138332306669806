import { useEffect, useCallback, useState } from 'react';
import { IProject, IProjectMetrics } from 'models/project';

import { useFetchProjects, useFetchProjectMetrics } from 'services/data/projects';

interface IProjectsResult {
  projects: IProject[];
}

const useProjects = (customerID?: string): IProjectsResult => {
  const [projects, setProjects] = useState<IProject[]>([]);
  const fetchProjects = useFetchProjects();

  const getProjects = useCallback(
    async (customerID: string) => {
      setProjects(await fetchProjects(customerID));
    },
    [fetchProjects]
  );

  useEffect(() => {
    if (customerID) {
      getProjects(customerID);
    } else {
      setProjects([]);
    }
  }, [getProjects, customerID]);

  return {
    projects
  };
};

interface IProjectMetricsResult {
  metrics: IProjectMetrics | null;
  loading: boolean;
}

const useProjectMetrics = (surveyID?: string): IProjectMetricsResult => {
  const [metrics, setMetrics] = useState<IProjectMetrics | null>(null);
  const [loading, setLoading] = useState(false);
  const fetchProjectMetrics = useFetchProjectMetrics();

  const getProjectMetrics = useCallback(
    async (surveyID: string) => {
      setLoading(true);
      setMetrics(await fetchProjectMetrics(surveyID));
      setLoading(false);
    },
    [fetchProjectMetrics]
  );

  useEffect(() => {
    if (surveyID) {
      setMetrics(null);
      getProjectMetrics(surveyID);
    } else {
      setMetrics(null);
    }
  }, [getProjectMetrics, surveyID]);

  return {
    metrics,
    loading
  };
};

const projectsHooks = {
  useProjects,
  useProjectMetrics
};

export default projectsHooks;
