import { useEffect, useMemo } from 'react';
import * as turf from '@turf/turf';
import WKT from 'terraformer-wkt-parser';
import { Map, LngLatBoundsLike } from 'mapbox-gl';

import { ITract } from 'models/tract';
import { IMapOptions } from 'models/map';
import { ISubTract } from 'models/sub-tract';

let mapResizer;
const ANIMATION_DURATION = 1000;

const useMapResizer = (map: Map | null, startInterval: boolean) => {
  useEffect(() => {
    clearInterval(mapResizer);

    if (map && startInterval) {
      mapResizer = setInterval(() => {
        map.resize();
      }, 5);
    }

    return () => {
      setTimeout(() => {
        map && map.resize();
        clearInterval(mapResizer);
      }, ANIMATION_DURATION);
    };
  }, [map, startInterval]);
};

const useGetMapOptions = (tracts: ITract[], subTracts: ISubTract[], selectedSubTract: ISubTract | null): IMapOptions | null => {
  const options = useMemo(() => {
    if (selectedSubTract) {
      const polygon = turf.feature(WKT.parse(selectedSubTract.WKT));
      const bbox = turf.bbox(turf.buffer(turf.featureCollection([polygon]), 0.02));
      return { bounds: turf.square(bbox) as LngLatBoundsLike };
    } else if (subTracts.length) {
      const polygons = subTracts.map((entry) => turf.feature(WKT.parse(entry.WKT)));
      const bbox = turf.bbox(turf.buffer(turf.featureCollection(polygons), 0.5));
      return { bounds: turf.square(bbox) as LngLatBoundsLike };
    } else if (tracts.length) {
      const polygons = tracts.map((entry) => {
        const res = turf.feature({
          type: 'Point',
          coordinates: JSON.parse(entry.geometry).coordinates[0][0][0]
        });
        return res;
      });
      const bbox = turf.bbox(turf.buffer(turf.featureCollection(polygons), 0.5));
      return { bounds: turf.square(bbox) as LngLatBoundsLike };
    }
    return null;
  }, [tracts, subTracts, selectedSubTract]);

  return options;
};

const mapHooks = {
  useMapResizer,
  useGetMapOptions
};

export default mapHooks;
