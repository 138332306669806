import { useEffect, useCallback, useState } from 'react';
import WKT from 'terraformer-wkt-parser';
import { useDispatch, useSelector } from 'react-redux';
import { wrap } from 'comlink';

import { useFetchTracts, useFetchTractMetric, useFetchTractsMetrics, useFetchSubTracts } from 'services/data/tracts';

import { setCachedSubTracts, getSubTractsSelector } from 'redux/tract/tractSlice';

// eslint-disable-next-line import/no-webpack-loader-syntax
import TractsWorker from 'worker-loader!../services/data/tracts.worker';

import { ITract } from 'models/tract';
import { ISubTract } from 'models/sub-tract';
import { ITractMetric } from 'models/tract-metric';
import { IPatch, IPatchMetric } from 'models/patch';

export interface ITractWithMetric extends ITract {
  /* eslint-disable camelcase */
  tCO2e_per_acre: number;
  /* eslint-enable camelcase */
  tCO2e: number;
}

interface ITractsResult {
  tracts: ITractWithMetric[];
  loading: boolean;
}

const useTracts = (projectID?: string, surveyID?: string): ITractsResult => {
  const [tracts, setTracts] = useState<ITractWithMetric[]>([]);
  const [loading, setLoading] = useState(false);
  const fetchTracts = useFetchTracts();
  const fetchTractsMetrics = useFetchTractsMetrics();

  const getTracts = useCallback(
    async (projectID: string, surveyID: string) => {
      setLoading(true);
      const metrics = await fetchTractsMetrics(surveyID);

      setTracts(
        (await fetchTracts(projectID))
          .map((entry) => {
            const metric = metrics.find((metric) => metric.business_unit_ID === entry.business_unit_ID);
            return {
              ...entry,
              geometry: metric?.geometry,
              /* eslint-disable camelcase */
              tCO2e_per_acre: metric?.tCO2e_per_acre || 0,
              /* eslint-enable camelcase */
              tCO2e: metric?.tCO2e || 0
            };
          })
          .filter((entry) => !!entry.tCO2e)
      );
      setLoading(false);
    },
    [fetchTracts, fetchTractsMetrics]
  );

  useEffect(() => {
    if (projectID && surveyID) {
      getTracts(projectID, surveyID);
    } else {
      setTracts([]);
    }
  }, [getTracts, projectID, surveyID]);

  return {
    tracts,
    loading
  };
};

interface ITractsMetricsResult {
  metric: ITractMetric | null;
  loading: boolean;
}

const useTractMetric = (tractID?: string, surveyID?: string): ITractsMetricsResult => {
  const [metric, setMetric] = useState<ITractMetric | null>(null);
  const [loading, setLoading] = useState(false);
  const fetchTractMetric = useFetchTractMetric();

  const getTractMetric = useCallback(
    async (tractID: string, surveyID: string) => {
      setLoading(true);
      setMetric(await fetchTractMetric(tractID, surveyID));
      setLoading(false);
    },
    [fetchTractMetric]
  );

  useEffect(() => {
    if (tractID && surveyID) {
      setMetric(null);
      getTractMetric(tractID, surveyID);
    } else {
      setMetric(null);
    }
  }, [getTractMetric, tractID, surveyID]);

  return {
    metric,
    loading
  };
};

interface ITractsPatchesResult {
  patches: IPatch[];
  loading: boolean;
}

const worker = new TractsWorker();
const workerApi = wrap<import('../services/data/tracts.worker').TractsWorker>(worker);

const useTractPatches = (tractID?: string, surveyID?: string): ITractsPatchesResult => {
  const [patches, setPatches] = useState<IPatch[]>([]);
  const [patchesMetrics, setPatchesMetrics] = useState<IPatchMetric[]>([]);
  const [mergedPatches, setMergedPatches] = useState<IPatch[]>([]);
  const [loading, setLoading] = useState(false);

  // prettier-ignore
  const getTractPatches = useCallback(async (tractID: string, surveyID: string) => {
    setPatches([]);
    setPatchesMetrics([]);
    setLoading(true);

    setPatches(
      (await workerApi.fetchTractPatches(tractID)).map((entry) => {
        const intersectionWkt = entry.business_units.find((entry) => entry.business_unit_ID === tractID)?.intersection_wkt;
        return {
          ...entry,
          geometry: WKT.parse(intersectionWkt || entry.WKT),
          tCO2e: 0,
          tCO2eRelative: 0
        };
      })
    );

    setPatchesMetrics(await workerApi.fetchPatchesMetrics(tractID, surveyID));
  }, []);

  useEffect(() => {
    if (tractID && surveyID) {
      getTractPatches(tractID, surveyID);
    } else {
      setPatches([]);
    }
  }, [getTractPatches, tractID, surveyID]);

  useEffect(() => {
    if (!patchesMetrics.length || !patches.length) {
      setMergedPatches(patches);
    } else {
      const mergePromise = workerApi.mergeMetrics(patches, patchesMetrics);

      mergePromise.then((data) => {
        setMergedPatches(data);
        setLoading(false);
      });
    }
  }, [patches, patchesMetrics]);

  return {
    patches: mergedPatches,
    loading
  };
};

interface ISubTractsResult {
  subTracts: ISubTract[];
  loading: boolean;
}

const useTractSubTracts = (tractIDs: string[]): ISubTractsResult => {
  const cachedSubTracts = useSelector(getSubTractsSelector);
  const dispatch = useDispatch();

  const [subTracts, setSubTracts] = useState<ISubTract[]>([]);
  const [loading, setLoading] = useState(false);
  const fetchSubTracts = useFetchSubTracts();

  useEffect(() => {
    if (cachedSubTracts.length) {
      setSubTracts(tractIDs.length === 1 ? cachedSubTracts.filter((entry) => entry.business_unit_ID === tractIDs[0]) : cachedSubTracts);
    }
  }, [cachedSubTracts, tractIDs]);

  const getSubTracts = useCallback(
    async (tractIDs: string[]) => {
      if (cachedSubTracts.length) return;
      setLoading(true);

      const chunks = tractIDs.reduce((acc: string[][], entry, index) => {
        const chunk = Math.round(index / 10);
        if (!acc[chunk]) {
          acc[chunk] = [];
        }
        acc[chunk].push(entry);
        return acc;
      }, []);

      const subTracts = (await Promise.all(chunks.map((chunk) => fetchSubTracts(chunk)))).flat();

      if (tractIDs.length > 1) {
        dispatch(setCachedSubTracts(subTracts));
      } else {
        setSubTracts(subTracts);
      }
      setLoading(false);
    },
    [fetchSubTracts, cachedSubTracts, dispatch]
  );

  useEffect(() => {
    if (tractIDs.length) {
      getSubTracts(tractIDs);
    } else {
      setSubTracts([]);
    }
  }, [getSubTracts, tractIDs]);

  return {
    subTracts,
    loading
  };
};

const tractsHooks = {
  useTracts,
  useTractMetric,
  useTractSubTracts,
  useTractPatches
};

export default tractsHooks;
