import React, { Suspense, useEffect, useState, useMemo } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import RequireAuth from 'components/signIn/RequireAuth';
import GlobalLoader from 'atomicComponents/GlobalLoader';

import signInHooks from 'hooks/signIn.hooks';
import tranlationsHooks from 'hooks/translations.hooks';
import useLogger from 'hooks/logger.hooks';
import { useAnalytics, useStoreUtmParams } from 'hooks/analytics.hooks';
import { IMapState, IMapOptions } from 'models/map';

import 'mapbox-gl/dist/mapbox-gl.css';
import styled from 'styled-components';
import { Map } from 'mapbox-gl';
import { MapContext } from 'components/shared/Map';
import MapLayout from 'layouts/MapLayout';
import MapScreen from 'screens/Map';
import SignOut from 'screens/SignOut';

import { Pages } from 'utils/routes';

const SignInScreen = React.lazy(() => import(/* webpackChunkName: "SingInScreen" */ 'screens/SingIn'));
const ForgotPasswordScreen = React.lazy(() => import(/* webpackChunkName: "ForgotPasswordScreen" */ 'screens/ForgotPassword'));
const NotFoundScreen = React.lazy(() => import(/* webpackChunkName: "NotFoundScreen" */ 'screens/NotFound'));
const DashboardScreen = React.lazy(() => import(/* webpackChunkName: "DashboardScreen" */ 'screens/Dashboard'));

const Layout = styled.div`
  display: flex;
  width: 100%;
`;

function App(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const user = signInHooks.useSignedInUser();
  const { setUser: setAnalyticsUser } = useAnalytics();
  const { setUser: setLoggerUser } = useLogger();
  const [activeMap, setActiveMap] = useState<Map | null>(null);
  const [mapState, setMapState] = useState<IMapState | null>(null);
  const [mapOptions, setMapOptions] = useState<IMapOptions | null>(null);
  const mapContext = useMemo(
    () => ({ activeMap, setActiveMap, mapState, setMapState, mapOptions, setMapOptions }),
    [activeMap, setActiveMap, mapState, setMapState, mapOptions, setMapOptions]
  );

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(Pages.Map);
    }
  }, [location, navigate]);

  useStoreUtmParams();
  signInHooks.useFetchSignedInUser();
  tranlationsHooks.useTranslationsInit();

  useEffect(() => {
    if (user) {
      setAnalyticsUser(user);
    }
  }, [user, setAnalyticsUser]);

  useEffect(() => {
    if (user) {
      setLoggerUser(user);
    }
  }, [user, setLoggerUser]);

  return (
    <div className="App">
      <MapContext.Provider value={mapContext}>
        <Suspense fallback={<GlobalLoader />}>
          <Routes>
            <Route path="/sign-in" element={<SignInScreen />} />
            <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
            <Route
              path="/map"
              element={
                <RequireAuth>
                  <Layout>
                    <MapLayout />
                  </Layout>
                </RequireAuth>
              }
            >
              <Route path="/map" element={<MapScreen />} />
              <Route path="/map/:projectID" element={<MapScreen />} />
              <Route path="/map/:projectID/:tractID" element={<MapScreen />} />
            </Route>
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <DashboardScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/:projectID"
              element={
                <RequireAuth>
                  <DashboardScreen />
                </RequireAuth>
              }
            />
            <Route path="/404" element={<NotFoundScreen />} />
            <Route
              path="/sign-out"
              element={
                <RequireAuth>
                  <SignOut />
                </RequireAuth>
              }
            />
            <Route path="*" element={<NotFoundScreen />} />
          </Routes>
        </Suspense>
      </MapContext.Provider>
    </div>
  );
}

export default App;
