import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from 'redux/auth/authSlice';
import { subTractReducer } from 'redux/tract/tractSlice';
import { appStateReducer } from './appStateSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    tracts: subTractReducer,
    appState: appStateReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
