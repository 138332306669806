import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { faChartColumn, faLocationDot, faBars } from '@fortawesome/pro-solid-svg-icons';
import { useLocation, matchPath } from 'react-router-dom';
import { mfColors, mfSizes } from 'vars';
import NavIcon from 'atomicComponents/NavIcon';
import IconButton, { EIconType } from 'atomicComponents/IconButton';
import signInHooks from 'hooks/signIn.hooks';
import { useDispatch, useSelector } from 'react-redux';
import { farmLink, Pages } from 'utils/routes';
import { setIsSideBarExpanded, isSideBarExpandedSelector, isUserMenuShownSelector, setIsUserMenuShown } from 'redux/appStateSlice';
import { ExpandedNavBarItemOutAnimation as navBarItemOutAnimation, ExpandedNavBarItemInAnimation as navBarItemInAnimation } from 'atomicComponents/animations';

const DEFAULT_TOP_POSITION = -40;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: ${mfSizes.staticMenuWidth};
  background-color: ${mfColors.navBarBackground};
  position: relative;
  z-index: 6;
`;

const StaticMenuPart = styled.div`
  flex: 0 0 ${mfSizes.staticMenuWidth};
  width: ${mfSizes.staticMenuWidth};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Entry = styled.div`
  margin: 15px 0;
  position: relative;
`;

const TopEntries = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`;

interface ISelectedProps {
  top: number;
  isExpanded: boolean;
}
/* eslint-disable */
const SelectedBorder = styled.div`
  position: absolute;
  top: ${({ top }: ISelectedProps) => (top ? `${top}px` : '0')};
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 2px solid;
  border-color: #31456a;
  transition: top 0.5s ease-in-out;
  ${({ isExpanded }: ISelectedProps) => (!isExpanded ? css`animation: ${navBarItemOutAnimation} 0.25s ease-in-out forwards;` : css`animation: ${navBarItemInAnimation} 0.25s ease-in-out forwards;`)}
`;
/* eslint-enable */
const REPORTS_SCREEN_CHECK_REGEX = /^\/$/;

const NavBar = () => {
  const { t } = useTranslation();
  const user = signInHooks.useSignedInUser();
  const location = useLocation();
  const isSideBarExpanded = useSelector(isSideBarExpandedSelector);
  const isUserMenuShown = useSelector(isUserMenuShownSelector);
  const dispatch = useDispatch();
  const locationUrl = useMemo(() => farmLink(Pages.Map), []);
  const dashboardUrl = useMemo(() => farmLink(Pages.Dashboard), []);
  const menuElement = useRef<HTMLDivElement>(null);
  const locationElement = useRef<HTMLDivElement>(null);
  const biDashboardElement = useRef<HTMLDivElement>(null);
  const selectedTopElement = useRef<HTMLDivElement>(null);
  const [selectedElementTopPosition, setSelectedElementTopPosition] = useState<number>(DEFAULT_TOP_POSITION);

  const isNavActive = useCallback(
    (url: string | null) => {
      if (url) {
        return !isUserMenuShown && location.pathname.indexOf(url) === 0;
      }

      return !isUserMenuShown && REPORTS_SCREEN_CHECK_REGEX.test(location.pathname);
    },
    [location, isUserMenuShown]
  );

  useEffect(() => {
    const resizeListener = () => {
      if (isNavActive(dashboardUrl)) {
        setTopPosition(biDashboardElement);
      } else if (isNavActive(locationUrl)) {
        setTopPosition(locationElement);
      }
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [isNavActive, locationUrl, dashboardUrl]);

  useEffect(() => {
    if (isUserMenuShown) {
      setTopPosition(menuElement);
    } else if (isNavActive(dashboardUrl)) {
      setTopPosition(biDashboardElement);
    } else if (isNavActive(locationUrl)) {
      setTopPosition(locationElement);
    }
  }, [isNavActive, locationUrl, dashboardUrl, isUserMenuShown]);

  const handleNavClicks = (url: string, element) => {
    const activePath = matchPath({ path: location.pathname, end: true }, url);
    // eslint-disable-next
    setTopPosition(element);

    if (isUserMenuShown) {
      dispatch(setIsUserMenuShown(false));

      return;
    }

    dispatch(setIsSideBarExpanded(true));

    if (activePath) {
      dispatch(setIsSideBarExpanded(false));
    }

    if (!isSideBarExpanded && activePath) {
      dispatch(setIsSideBarExpanded(true));
    }
  };

  const userMenuClickHandler = () => {
    if (!isSideBarExpanded) {
      dispatch(setIsSideBarExpanded(true));
    }

    dispatch(setIsUserMenuShown(!isUserMenuShown));
  };

  const setTopPosition = (element) => {
    if (!element || !element.current || !selectedTopElement || !selectedTopElement.current) {
      return setSelectedElementTopPosition(DEFAULT_TOP_POSITION);
    }

    const clickTopPosition = element.current.getBoundingClientRect().top;
    const activeTop = selectedTopElement?.current?.getBoundingClientRect().top;
    if (activeTop === clickTopPosition) {
      return null;
    }

    return setSelectedElementTopPosition(clickTopPosition);
  };

  const isDashboardActive = window.location.pathname.indexOf(dashboardUrl) === 0;
  const isMapActive = window.location.pathname.indexOf(locationUrl) === 0;

  return (
    <Wrapper>
      <StaticMenuPart>
        <SelectedBorder ref={selectedTopElement} top={selectedElementTopPosition} isExpanded={isSideBarExpanded} />
        {user && (
          <TopEntries>
            <Entry ref={menuElement}>
              <IconButton active={isUserMenuShown} icon={faBars} iconType={EIconType.FontAwesome} iconSize="lg" onClick={() => userMenuClickHandler()} />
            </Entry>
          </TopEntries>
        )}
        <Entry ref={locationElement} onClick={() => handleNavClicks(locationUrl, locationElement)}>
          <NavIcon disabled={isMapActive} icon={faLocationDot} iconSize="lg" url={locationUrl} active={isNavActive(locationUrl)} tooltip={t('shared.nav_bar.explore')} />
        </Entry>
        <Entry ref={biDashboardElement} onClick={() => handleNavClicks(dashboardUrl, biDashboardElement)}>
          <NavIcon disabled={isDashboardActive} icon={faChartColumn} iconSize="lg" url={dashboardUrl} active={isNavActive(dashboardUrl)} tooltip={t('shared.nav_bar.dashboard')} />
        </Entry>
      </StaticMenuPart>
    </Wrapper>
  );
};

export default NavBar;
