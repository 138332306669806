import { useCallback } from 'react';
import { collection, getDocs, QueryConstraint, where, query } from 'firebase/firestore';
import db from 'services/data/providers/firebase';
import { ITract } from 'models/tract';
import { ISubTract } from 'models/sub-tract';
import { ITractMetric } from 'models/tract-metric';
import useLogger, { EStorageType } from 'hooks/logger.hooks';
import api from './providers/api';

export const useFetchTracts = (): ((projectID: string) => Promise<ITract[]>) => {
  const { logError } = useLogger();

  return useCallback(
    async (projectID): Promise<ITract[]> => {
      const tractsCollection = collection(db.firestore, '/carbon-business-units');
      const queryParams = [] as QueryConstraint[];
      queryParams.push(where('project_ID', '==', projectID));

      try {
        const q = query(tractsCollection, ...queryParams);
        const res = await getDocs(q).then((docs) => {
          const res: ITract[] = [];
          docs.forEach((doc) => {
            const data = doc.data();
            res.push({
              id: data.business_unit_ID,
              ...data
            } as ITract);
          });
          return res;
        });
        return res;
      } catch (e) {
        logError(e as Error, '/carbon-business-units', EStorageType.Firestore);
        return Promise.resolve([]);
      }
    },
    [logError]
  );
};

export const useFetchTractsMetrics = (): ((surveyID: string) => Promise<ITractMetric[]>) => {
  const { logError } = useLogger();

  return useCallback(
    async (surveyID: string): Promise<ITractMetric[]> => {
      const tractsCollection = collection(db.firestore, '/carbon-product-metrics-business-unit');
      const queryParams = [] as QueryConstraint[];
      queryParams.push(where('surveyID', '==', surveyID));

      try {
        const q = query(tractsCollection, ...queryParams);
        const res = await getDocs(q).then((docs) => {
          const res: ITractMetric[] = [];
          docs.forEach((doc) => {
            res.push({
              id: doc.id,
              ...doc.data()
            } as ITractMetric);
          });
          return res;
        });
        return res;
      } catch (e) {
        logError(e as Error, '/carbon-product-metrics-business-unit', EStorageType.Firestore);
        return Promise.resolve([]);
      }
    },
    [logError]
  );
};

export const useFetchTractMetric = (): ((tractID: string, surveyID: string) => Promise<ITractMetric | null>) => {
  const { logError } = useLogger();

  return useCallback(
    async (tractID: string, surveyID: string): Promise<ITractMetric | null> => {
      const tractsCollection = collection(db.firestore, '/carbon-product-metrics-business-unit');
      const queryParams = [] as QueryConstraint[];
      queryParams.push(where('business_unit_ID', '==', tractID));
      queryParams.push(where('surveyID', '==', surveyID));

      try {
        const q = query(tractsCollection, ...queryParams);
        const res = await getDocs(q).then(async (docs) => {
          const res: ITractMetric[] = [];
          docs.forEach((doc) => {
            res.push({
              id: doc.id,
              ...doc.data()
            } as ITractMetric);
          });
          return res[0] || null;
        });
        return res;
      } catch (e) {
        logError(e as Error, '/carbon-product-metrics-business-unit', EStorageType.Firestore);
        return Promise.resolve(null);
      }
    },
    [logError]
  );
};

export const useFetchSubTracts = (): ((tractIDs: string[]) => Promise<ISubTract[]>) => {
  const { logError } = useLogger();

  return useCallback(
    async (tractIDs): Promise<ISubTract[]> => {
      const tractsCollection = collection(db.firestore, '/carbon-sub-business-units');
      const queryParams = [] as QueryConstraint[];
      queryParams.push(where('business_unit_ID', 'in', tractIDs));

      try {
        const q = query(tractsCollection, ...queryParams);
        const res = await getDocs(q).then(async (docs) => {
          const res: ISubTract[] = [];
          docs.forEach((doc) => {
            res.push({
              id: doc.id,
              ...doc.data()
            } as unknown as ISubTract);
          });
          return res;
        });
        return res;
      } catch (e) {
        logError(e as Error, '/carbon-sub-business-units', EStorageType.Firestore);
        return Promise.resolve([]);
      }
    },
    [logError]
  );
};

const getPatchImages = async (patchID: string) => {
  const res = await api.get(`https://us-central1-seetree-carbon.cloudfunctions.net/getSampleImages?patchID=${patchID}`, false);

  return res;
};

const tractsService = {
  useFetchTracts,
  useFetchTractMetric,
  useFetchTractsMetrics,
  useFetchSubTracts,
  getPatchImages
};

export default tractsService;
