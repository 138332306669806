import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';
import { ISubTract } from 'models/sub-tract';

interface IAuthState {
  subTracts: ISubTract[];
}

const slice = createSlice({
  name: 'tract',
  initialState: { subTracts: [] } as IAuthState,
  reducers: {
    setSubTracts: (state, action) => ({ ...state, subTracts: action.payload })
  }
});

export const { setSubTracts: setCachedSubTracts } = slice.actions;
export const subTractReducer = slice.reducer;
export const getSubTractsSelector = (state: RootState) => state.tracts.subTracts;
