import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Map, Popup } from 'mapbox-gl';

import styled from 'styled-components';
import { mfColors } from 'vars';
import ImageGallery from 'components/gallery/ImageGallery';
import tractsService from 'services/data/tracts';
import SkeletonLoader from 'atomicComponents/SkeletonLoader';

const Wrapper = styled.div`
  font-size: 14px;
  border-radius: 16px;
  padding: 0;
  font-weight: 400;
  margin: -12px 0 0;
  overflow: hidden;

  &.static {
    background: ${mfColors.white};
    margin-top: 20px;
    border-radius: 16px;
  }
`;

const Content = styled.div`
  padding: 0px 5px;
  max-height: 264px;
  overflow: auto;
`;

const ContentRow = styled.div`
  display: flex;
  line-height: 40px;
  align-items: center;
  justify-content: space-between;

  .note {
    width: 100%;
    font-family: MontserratBold;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  .title {
    font-family: MontserratBold;
    min-width: 80px;
    max-width: 216px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.fullsize {
      max-width: 100%;
      white-space: normal;
    }

    &.has-subtitle {
      line-height: 20px;
    }

    .sub-title {
      line-height: 20px;
      font-size: 12px;
      font-family: Montserrat;
    }
  }

  .value {
    max-width: 148px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.loader {
    padding: 10px 0;

    & > div {
      margin: 0 4px;
    }
  }
`;

interface IProps {
  data: any;
  onImageClick: (image: string) => void;
}

const CarbonPatchPopup = ({ data, onImageClick }: IProps): JSX.Element | null => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [images, setImages] = React.useState<any[]>([]);

  useEffect(() => {
    async function getImages() {
      if (!data.patchID) return;
      setLoading(true);
      try {
        const res = (await tractsService.getPatchImages(data.patchID)) as any;

        setImages(res);
      } catch (e) {
        setImages([]);
      } finally {
        setLoading(false);
      }
    }

    getImages();
  }, [data.patchID]);

  return (
    <Wrapper>
      <Content>
        <ContentRow>{!loading && <ImageGallery images={images} onImageClick={onImageClick} />}</ContentRow>
        {loading && (
          <ContentRow className="loader">
            <SkeletonLoader width="100px" height="100px" borderRadius={8} />
            <SkeletonLoader width="100px" height="100px" borderRadius={8} />
          </ContentRow>
        )}
      </Content>
    </Wrapper>
  );
};

const PATCH_CLASS_NAME = 'carbon-patch-popup';

export const showCarbonPatchPopup = (map: Map, data, onImageClick: (image: string) => void): void => {
  const popups = document.querySelectorAll(`.${PATCH_CLASS_NAME}`);
  for (let i = 0; i < popups.length; i += 1) {
    popups[i].remove();
  }

  const { geometry } = data;
  const popupNode = document.createElement('div');
  ReactDOM.render(<CarbonPatchPopup data={data} onImageClick={onImageClick} />, popupNode);

  const popup = new Popup({ maxWidth: '320px', closeButton: false, className: PATCH_CLASS_NAME, offset: [0, -30] })
    .setLngLat(geometry.coordinates)
    .setDOMContent(popupNode)
    .addTo(map);

  popup.on('close', () => {
    popupNode.remove();
  });
};

export default CarbonPatchPopup;
