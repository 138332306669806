import React, { useState, useEffect, useRef, createContext, useContext } from 'react';
import mapboxgl from 'mapbox-gl';
import styled from 'styled-components';
import appConfig from 'config/config.json';
import { IMapState } from 'models/map';

import { addSubTractsLayers } from 'services/map/mapSubTract';
import { addTractLayers } from 'services/map/mapTract';

mapboxgl.accessToken = appConfig.mapBoxToken;

interface IWrapperProps {
  isFullScreen: boolean;
}

const Wrapper = styled.div<IWrapperProps>`
  width: 100%;
  height: 100%;
  position: relative;
`;

type TOptions = Pick<mapboxgl.MapboxOptions, 'bounds' | 'center' | 'zoom'> & { zoomDuration?: number };

interface IProps {
  options?: TOptions;
  onMapInit?: (map: mapboxgl.Map) => void;
  isFullScreen?: boolean;
}

const MAPBOX_STYLE = 'mapbox://styles/mapbox/satellite-v9';

interface IMapContext {
  activeMap: mapboxgl.Map | null;
  setActiveMap: (map: mapboxgl.Map) => void;
  mapState: IMapState | null;
  setMapState: (state: IMapState | null) => void;
  setMapOptions: (state: TOptions) => void;
  mapOptions: TOptions | null;
}

export const MapContext = createContext<IMapContext>({
  activeMap: null,
  setActiveMap: (map: mapboxgl.Map) => null,
  setMapOptions: () => null,
  mapOptions: null,
  mapState: null,
  setMapState: (state: any) => null
});

const Map = ({ options, onMapInit, isFullScreen = true }: IProps) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const { setActiveMap, mapOptions: globalMapOptions } = useContext(MapContext);
  const mapOptions = options || globalMapOptions;

  useEffect(() => {
    if (mapContainer.current) {
      const mapbox = new mapboxgl.Map({
        container: mapContainer.current,
        style: MAPBOX_STYLE,
        attributionControl: false,
        maxZoom: 18,
        minZoom: 5
      });
      mapbox.on('load', () => {
        addTractLayers(mapbox);
        addSubTractsLayers(mapbox);
        setMap(mapbox);
      });
    }

    return () => {
      setMap(null);
    };
  }, [mapContainer]);
  useEffect(() => {
    if (map && mapOptions?.center && mapOptions?.zoom) {
      map.setCenter(mapOptions.center);
      map.setZoom(mapOptions.zoom);
      return;
    }
    if (map && mapOptions?.bounds) {
      if (mapOptions?.zoomDuration !== undefined) {
        map.fitBounds(mapOptions.bounds, { duration: mapOptions.zoomDuration });
      } else {
        map.fitBounds(mapOptions.bounds);
      }
    }
  }, [map, mapOptions]);

  useEffect(() => {
    if (map && onMapInit) {
      onMapInit(map);
    }
  }, [map, onMapInit]);

  useEffect(() => {
    if (map && !onMapInit) {
      setActiveMap(map);
    }
  }, [map, onMapInit, setActiveMap]);

  return <Wrapper isFullScreen={isFullScreen} style={{ height: '100%', width: '100%' }} ref={mapContainer} />;
};

export default Map;
