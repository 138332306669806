import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IAuthRequest } from 'models/auth';
import { IUser } from 'models/user';
import { CUSTOMER_ID } from 'models/customer';

import db from 'services/data/providers/firebase';
import userMapperService from 'services/mappers/user';

import { signInByCredentials, signOut, getAuthErrored, getUser, setUser } from 'redux/auth/authSlice';

type TSignInAction = (params: IAuthRequest) => void;

interface ISignIn {
  signIn: TSignInAction;
  signInErrored: boolean;
}

const useSignIn = (): ISignIn => {
  const dispatch = useDispatch();
  const signInErrored = useSelector(getAuthErrored);
  const signIn = useCallback(
    (params: IAuthRequest): void => {
      dispatch(signInByCredentials(params));
    },
    [dispatch]
  );

  return {
    signIn,
    signInErrored
  };
};

const useSignOut = (): (() => void) => {
  const dispatch = useDispatch();
  return useCallback((): void => {
    dispatch(signOut());
  }, [dispatch]);
};

const useSignedInUser = (): IUser | null => useSelector(getUser);

const useFetchSignedInUser = (): void => {
  const { isAuthorized } = useGetSignedInStatus();
  const dispatch = useDispatch();
  const user = useSignedInUser();

  useEffect(() => {
    if (isAuthorized && !user) {
      dispatch(setUser(db.user ? { ...userMapperService.mapSignedInUser(db.user), customerID: CUSTOMER_ID } : null));
    }
  }, [dispatch, isAuthorized, user]);
};

interface IGetSignedInStatusResult {
  isAuthorized: boolean;
  isLoading: boolean;
}

const AUTH_LOADER_TIMEOUT = 600;

const useGetSignedInStatus = (): IGetSignedInStatusResult => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(!!db.user);

  const authStateChangeHandler = useCallback(
    (authStatus: boolean) => {
      setIsAuthorized(authStatus);
      setIsLoading(false);
    },
    [setIsLoading, setIsAuthorized]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, AUTH_LOADER_TIMEOUT);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  });

  useEffect(() => {
    db.subscribeAuthStateChanged(authStateChangeHandler);
    return () => {
      db.unsubscribeAuthStateChanged(authStateChangeHandler);
    };
  }, [authStateChangeHandler]);

  return {
    isAuthorized,
    isLoading
  };
};

const signInHooks = {
  useSignIn,
  useSignOut,
  useFetchSignedInUser,
  useSignedInUser,
  useGetSignedInStatus
};

export default signInHooks;
