import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

interface IProps {
  width: string;
  height: string;
  borderRadius?: number;
}

const SkeletonLoader = styled.div<IProps>`
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  border-radius: ${({ borderRadius = 5 }) => `${borderRadius}px`};
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #e8e8e8 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  animation: ${shimmer} 1.2s linear infinite;
`;

export default SkeletonLoader;
