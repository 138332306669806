import Map, { MapContext } from 'components/shared/Map';
import NavBar from 'containers/NavBar';
import mapHooks from 'hooks/map.hooks';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { isSideBarExpandedSelector } from 'redux/appStateSlice';
import styled from 'styled-components';

import { mfSizes } from 'vars';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100%;
  width: 100%;
  height: 100%;
`;
interface IWrapperProps {
  sideBarExpanded: boolean;
}

const emptyWidth = '0px';

const OutletWrapper = styled.div`
  width: ${({ sideBarExpanded }: IWrapperProps) => (sideBarExpanded ? mfSizes.sideBarAreaWidth : emptyWidth)};
  height: ${({ sideBarExpanded }: IWrapperProps) => (sideBarExpanded ? '100%' : emptyWidth)};
  transition: width 0.5s;
`;

const MapSectionWrapper = styled.div`
  width: calc(100% - ${({ sideBarExpanded }: IWrapperProps) => (sideBarExpanded ? mfSizes.sideBarAreaWidth : emptyWidth)});
  height: 100%;
`;

const MapLayout = () => {
  const sideBarExpanded = useSelector(isSideBarExpandedSelector);
  const { activeMap } = useContext(MapContext);

  mapHooks.useMapResizer(activeMap, !sideBarExpanded);

  return (
    <Wrapper>
      <NavBar />
      <OutletWrapper sideBarExpanded={sideBarExpanded}>
        <Outlet />
      </OutletWrapper>
      <MapSectionWrapper sideBarExpanded={sideBarExpanded}>
        <Map />
      </MapSectionWrapper>
    </Wrapper>
  );
};

export default MapLayout;
