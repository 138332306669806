import React, { useEffect, useState } from 'react';
import Modal from 'atomicComponents/Modal';
import { useModal } from 'react-modal-hook';
import styled from 'styled-components';

const ExpandedImg = styled.img`
  height: 720px;
  border: 0;
  margin: 20px 0 0;
  border-radius: 16px;
`;

const useImageModal = () => {
  const [expandedTreeImage, setExpandedTreeImage] = useState<string>('');
  const [showDetailedImageModal, hideDetailedImageModal] = useModal(
    () => (
      <Modal
        width="auto"
        onClose={() => {
          hideDetailedImageModal();
          setExpandedTreeImage('');
        }}
      >
        {expandedTreeImage && <ExpandedImg src={expandedTreeImage} alt="" />}
      </Modal>
    ),
    [expandedTreeImage]
  );

  useEffect(() => {
    if (expandedTreeImage) {
      showDetailedImageModal();
    } else {
      hideDetailedImageModal();
    }
  }, [expandedTreeImage, showDetailedImageModal, hideDetailedImageModal]);

  return {
    expandedTreeImage,
    setExpandedTreeImage
  };
};

const imageHooks = {
  useImageModal
};

export default imageHooks;
