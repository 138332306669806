import React, { useEffect, useMemo, useCallback, useContext, useState, useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import * as turf from '@turf/turf';

import { isSideBarExpandedSelector } from 'redux/appStateSlice';
import signInHooks from 'hooks/signIn.hooks';
import projectsHooks from 'hooks/projects.hooks';
import customersHooks from 'hooks/customers.hooks';
import tractsHooks from 'hooks/tracts.hooks';
import surveysHooks from 'hooks/surveys.hooks';
import mapHooks from 'hooks/map.hooks';
import { drawSubTracts, drawSelectedSubTracts, clearHoveredSubTract, drawHoveredSubTract, setTractEventHandlers, removeTractEventHandlers } from 'services/map/mapSubTract';
import { drawTracts, clearTracts } from 'services/map/mapTract';
import mapHeatmapService from 'services/map/mapHeatmap';
import { PATCH_FILL, TRACTS_LAYERS } from 'services/map/mapHelpers';

import SideBar from 'containers/SideBar';
import MainPageBreadcrumbs from 'components/main/MainPageBreadcrumbs/MainPageBreadcrumbs';
import LocalLoader from 'atomicComponents/LocalLoader';
import MainPageMenu from 'containers/MainPageMenu';
import AreaMetric from 'components/main/AreaMetric/AreaMetric';
import TCO2ELegend from 'components/main/TCO2ELegend/TCO2ELegend';
import { MapContext } from 'components/shared/Map';

import { ISubTract } from 'models/sub-tract';

import { mfSizes, mfColors } from 'vars';
import { showCarbonPatchPopup } from 'components/popups/CarbonPatch';
import imageHooks from 'hooks/image.hooks';

const StaticContent = styled.div`
  margin: 0 16px 0 0;
`;

interface IMapLoaderProps {
  isExpanded: boolean;
}

const getMapLoaderLeft = ({ isExpanded }: IMapLoaderProps) => {
  if (!isExpanded) return `50% + ${mfSizes.staticMenuWidth} / 2 - 12px`;
  return `50% + ${mfSizes.sideBarAreaWidth} / 2 + ${mfSizes.staticMenuWidth} / 2 - 12px`;
};

const MapLoader = styled.div`
  position: absolute;
  bottom: 32px;
  width: 48px;
  height: 48px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  background: ${mfColors.white};
  left: calc(${getMapLoaderLeft});
  z-index: 2;
`;

const MapLegend = styled.div`
  position: absolute;
  bottom: 16px;
  z-index: 2;
  left: calc(${mfSizes.sideBarAreaWidth} + ${mfSizes.staticMenuWidth} + 32px);
`;

const Map = (): JSX.Element => {
  const navigate = useNavigate();
  const isSideBarExpanded = useSelector(isSideBarExpandedSelector);
  const user = signInHooks.useSignedInUser();
  const { projects } = projectsHooks.useProjects(user?.customerID);
  const { customer } = customersHooks.useCustomer(user?.customerID);
  const params = useParams();
  const projectIDRef = useRef<string | null>(null);
  const tractIDRef = useRef<string | null>(null);

  useEffect(() => {
    projectIDRef.current = params.projectID || null;
    tractIDRef.current = params.tractID || null;
  }, [params.projectID, params.tractID]);

  const project = useMemo(() => {
    if (!params.projectID) return null;
    return projects.find((project) => project.id === params.projectID) || null;
  }, [params.projectID, projects]);

  const { activeMap: map, setMapOptions } = useContext(MapContext);

  const { surveys } = surveysHooks.useSurveys(project?.id);

  const { selectedSurvey, setSelectedSurvey } = surveysHooks.useSelectedSurvey(surveys);

  const { tracts, loading: tractsLoading } = tractsHooks.useTracts(project?.id, selectedSurvey?.id);

  const { metric: tractMetric, loading: tractLoading } = tractsHooks.useTractMetric(params.tractID, selectedSurvey?.id);
  const { metrics: projectMetric } = projectsHooks.useProjectMetrics(selectedSurvey?.id);

  const { patches, loading: patchesLoading } = tractsHooks.useTractPatches(params.tractID, selectedSurvey?.id);

  const tract = useMemo(() => {
    if (!params.tractID) return null;
    return tracts.find((entry) => entry.id === params.tractID) || null;
  }, [params.tractID, tracts]);
  const [selectedSubTract, setSelectedSubTract] = useState<ISubTract | null>(null);

  const subTractParams = useMemo(() => (params.tractID ? [params.tractID] : []), [params.tractID]);

  const { subTracts } = tractsHooks.useTractSubTracts(subTractParams);
  const allSubTractParams = useMemo(() => tracts.map((tract) => tract.business_unit_ID), [tracts]);
  const { subTracts: allSubTracts } = tractsHooks.useTractSubTracts(allSubTractParams);

  const mapOptions = mapHooks.useGetMapOptions(tracts, subTracts, selectedSubTract);
  const { setExpandedTreeImage } = imageHooks.useImageModal();

  useEffect(() => {
    if (mapOptions?.bounds) {
      setMapOptions(mapOptions);
    }
  }, [mapOptions, setMapOptions]);

  useEffect(() => {
    if (!params.projectID && projects?.length) {
      navigate(`/map/${projects[0].id}`);
    }
  }, [params, navigate, projects]);

  useEffect(() => {
    setSelectedSubTract((prev) => {
      if (!!prev && !subTracts.find((entry) => entry.id === prev.id)) {
        return null;
      }
      return prev;
    });
  }, [subTracts]);

  useEffect(() => {
    const zoomHandler = debounce(() => {
      if (!map) return;
      const zoom = map.getZoom();
      try {
        const heatmapFeatures = map.queryRenderedFeatures(undefined, { layers: [PATCH_FILL] });
        if (zoom < 12.4 && tractIDRef.current && heatmapFeatures.length > 0) {
          navigate(`/map/${projectIDRef.current}`);
        }
        if (zoom > 12.5 && !tractIDRef.current) {
          const tractFeatures = map.queryRenderedFeatures(undefined, { layers: [TRACTS_LAYERS[1]] });
          if (tractFeatures.length === 1 && tractFeatures[0]?.properties?.id) {
            navigate(`/map/${projectIDRef.current}/${tractFeatures[0].properties.id}`);
          }
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }, 200);
    if (map) {
      map.on('zoomend', zoomHandler);
    }
    return () => {
      if (map) {
        map.off('zoomend', zoomHandler);
      }
    };
  }, [map, navigate]);

  const subTractClickHandler = useCallback(
    (event?: any): void => {
      event.preventDefault();

      const properties = event.features?.[0].properties;
      const subTractID = properties?.id;

      const subTract = allSubTracts.find((entry) => entry.id === subTractID) || null;

      setSelectedSubTract((prev) => {
        if (prev?.id === subTractID) return prev;
        return subTract;
      });

      if (subTract && !!params.projectID && subTract.business_unit_ID !== params.tractID) {
        navigate(`/map/${params.projectID}/${subTract.business_unit_ID}`);
      }
    },
    [allSubTracts, navigate, params.projectID, params.tractID]
  );

  const subTractMousemoveHandler = useCallback(
    (event) => {
      const subTract = allSubTracts.find((subTract) => subTract.id === event.features?.[0].properties?.id);
      if (!subTract || !map || subTract.id === selectedSubTract?.id) {
        return;
      }

      drawHoveredSubTract(map, subTract);
    },
    [map, allSubTracts, selectedSubTract?.id]
  );

  const subTractMouseleaveHandler = useCallback(() => {
    if (!map) {
      return;
    }

    clearHoveredSubTract(map);
  }, [map]);

  useEffect(() => {
    if (map) {
      setTractEventHandlers(map, subTractClickHandler, subTractMousemoveHandler, subTractMouseleaveHandler);
    }

    return () => {
      if (map) {
        removeTractEventHandlers(map, subTractClickHandler, subTractMousemoveHandler, subTractMouseleaveHandler);
      }
    };
  }, [map, subTractClickHandler, subTractMousemoveHandler, subTractMouseleaveHandler]);

  useEffect(() => {
    if (map && allSubTracts.length && tract) {
      if (selectedSubTract) {
        clearHoveredSubTract(map);
      }

      drawSubTracts(
        map,
        allSubTracts.map((entry) => ({
          ...entry,
          tCO2e_per_acre: tracts.find((tractEntry) => tractEntry.id === entry.business_unit_ID)?.tCO2e_per_acre || 0
        }))
      );
    }

    return () => {
      if (map) {
        drawSubTracts(map, []);
      }
    };
  }, [map, tracts, tract, allSubTracts, selectedSubTract]);

  useEffect(() => {
    if (map && patches.length) {
      mapHeatmapService.drawPatches(map, patches);
    }

    return () => {
      if (map) {
        mapHeatmapService.removePatches(map);
      }
    };
  }, [map, patches]);

  useEffect(() => {
    const clickHandler = (event?: any) => {
      if (!params.projectID) return;
      const clickedTract = tracts.find((entry) => entry.id === event.features?.[0].properties?.id);

      if (clickedTract) {
        navigate(`/map/${params.projectID}/${clickedTract.id}`);
      }
    };

    if (map && tracts.length && !tract) {
      drawTracts(map, tracts, clickHandler);
    }

    return () => {
      if (map) {
        clearTracts(map, clickHandler);
      }
    };
  }, [map, tracts, tract, navigate, params.projectID]);

  const drawSelectedPatch = useCallback(
    async (event) => {
      if (!map) {
        return;
      }

      const feature = event.features?.[0];
      const { geometry, properties } = feature;

      const center = turf.centerOfMass(geometry);
      const popupInfo = {
        ...properties,
        geometry: center.geometry
      };

      mapHeatmapService.drawSelectedPatchData(map, feature);

      showCarbonPatchPopup(map, popupInfo, setExpandedTreeImage);
    },
    [map, setExpandedTreeImage]
  );

  const patchMousemoveHandler = useCallback(
    (event) => {
      if (!map) return;

      const feature = event.features?.[0];
      const hoveredFeature = turf.feature(feature.geometry, {});

      mapHeatmapService.drawHoveredPatchData(map, [hoveredFeature]);
    },
    [map]
  );

  const trackingPointsMouseleaveHandler = useCallback(() => {
    if (!map) {
      return;
    }

    mapHeatmapService.drawHoveredPatchData(map, [], '');
  }, [map]);

  useEffect(() => {
    if (map) {
      mapHeatmapService.setPatchEventHandlers(map, drawSelectedPatch, patchMousemoveHandler, trackingPointsMouseleaveHandler);
    }

    return () => {
      if (map) {
        mapHeatmapService.removePatchEventHandlers(map, drawSelectedPatch, patchMousemoveHandler, trackingPointsMouseleaveHandler);
      }
    };
  }, [map, drawSelectedPatch, patchMousemoveHandler, trackingPointsMouseleaveHandler]);

  useEffect(() => {
    if (map) {
      drawSelectedSubTracts(map, subTracts, selectedSubTract ? [selectedSubTract.id] : []);
    }

    return () => {
      if (map) {
        drawSelectedSubTracts(map, [], []);
      }
    };
  }, [map, subTracts, selectedSubTract]);

  if (!customer || !project || !selectedSurvey) {
    return (
      <SideBar>
        <LocalLoader />
      </SideBar>
    );
  }

  return (
    <>
      {isSideBarExpanded && (
        <SideBar>
          <StaticContent>
            <MainPageBreadcrumbs customers={[]} projects={projects} tracts={tracts} tract={tract} project={project} customer={customer} />
            <MainPageMenu
              selectedSurvey={selectedSurvey.id}
              onSelectSurvey={setSelectedSurvey}
              surveys={surveys}
              projects={projects}
              tracts={tracts}
              project={project}
              tract={tract}
            />
          </StaticContent>
          {tractLoading && <LocalLoader />}
          {(!!tractMetric || !!projectMetric) && <AreaMetric tract={tract} metric={tractMetric || projectMetric} />}
        </SideBar>
      )}
      <MapLegend>
        <TCO2ELegend />
      </MapLegend>
      {(tractsLoading || (patchesLoading && !!tract)) && (
        <MapLoader isExpanded={isSideBarExpanded}>
          <LocalLoader />
        </MapLoader>
      )}
    </>
  );
};

export default Map;
