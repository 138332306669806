import React, { useMemo } from 'react';
import styled from 'styled-components';

import { IProject } from 'models/project';
import { ITract } from 'models/tract';
import { ISurvey } from 'models/survey';

import StaticCard from 'atomicComponents/StaticCard';
import TractSearch from 'containers/TractSearch';
import SurveyTimeline from 'components/main/Timeline/SurveyTimeline';

import { mfColors, mfShadows } from 'vars';

const Wrapper = styled.div`
  margin: 0 0 8px;
`;

interface IProps {
  project: IProject;
  tract: ITract | null;
  projects: IProject[];
  tracts: ITract[];
  surveys: ISurvey[];
  selectedSurvey: string;
  onSelectSurvey: (survey: ISurvey) => void;
  prefix?: string;
}

const MainPageMenu = ({ project, projects, tract, tracts, selectedSurvey, onSelectSurvey, surveys, prefix = '/map' }: IProps): JSX.Element => {
  const surveysToShow = useMemo(() => surveys.filter((entry) => entry.id === selectedSurvey), [surveys, selectedSurvey]);
  return (
    <Wrapper>
      <TractSearch project={project} tract={tract} projects={projects} tracts={tracts} prefix={prefix} />
      {selectedSurvey && (
        <StaticCard
          padding="24px"
          width="100%"
          shadow={mfShadows.timelineShadow}
          background={mfColors.white}
          content={<SurveyTimeline onSelectSurvey={onSelectSurvey} surveys={surveysToShow} selectedSurvey={selectedSurvey} />}
        />
      )}
    </Wrapper>
  );
};

export default MainPageMenu;
