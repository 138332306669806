import { useCallback } from 'react';
import { collection, getDocs, QueryConstraint, where, query } from 'firebase/firestore';
import db from 'services/data/providers/firebase';
import { IProject, IProjectMetrics } from 'models/project';
import useLogger, { EStorageType } from 'hooks/logger.hooks';

export const useFetchProjects = (): ((customerID: string) => Promise<IProject[]>) => {
  const { logError } = useLogger();

  return useCallback(
    async (customerID): Promise<IProject[]> => {
      const projectsCollection = collection(db.firestore, '/carbon-projects');
      const queryParams = [] as QueryConstraint[];
      queryParams.push(where('customer_ID', '==', customerID));

      try {
        const q = query(projectsCollection, ...queryParams);
        const res = await getDocs(q).then((docs) => {
          const combinations: IProject[] = [];
          docs.forEach((doc) => {
            combinations.push({
              id: doc.id,
              ...doc.data()
            } as IProject);
          });
          return combinations;
        });
        return res;
      } catch (e) {
        logError(e as Error, '/carbon-projects', EStorageType.Firestore);
        return Promise.resolve([]);
      }
    },
    [logError]
  );
};

export const useFetchProjectMetrics = (): ((surveyID: string) => Promise<IProjectMetrics | null>) => {
  const { logError } = useLogger();

  return useCallback(
    async (surveyID): Promise<IProjectMetrics | null> => {
      const path = '/carbon-product-metrics-project';
      const metricsCollection = collection(db.firestore, path);
      const queryParams = [] as QueryConstraint[];
      queryParams.push(where('surveyID', '==', surveyID));

      try {
        const q = query(metricsCollection, ...queryParams);
        return await getDocs(q).then((docs) => {
          if (!docs.docs[0]) return null;
          return {
            id: docs.docs[0].id,
            ...docs.docs[0].data()
          } as unknown as IProjectMetrics;
        });
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
        return Promise.resolve(null);
      }
    },
    [logError]
  );
};

const projectsService = {
  useFetchProjects,
  useFetchProjectMetrics
};

export default projectsService;
