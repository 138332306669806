import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';
import { IAuthRequest } from 'models/auth';
import { IUser } from 'models/user';
import authService from 'services/data/auth';
import userMapperService from 'services/mappers/user';

interface IAuthState {
  user: IUser | null;
  authErrored: boolean;
}

export const signInByCredentials = createAsyncThunk('auth/signInByCredentials', async (params: IAuthRequest): Promise<IUser> => {
  const response = await authService.signIn(params.email, params.password);
  return userMapperService.mapSignedInUser(response.user as any as IUser);
});

export const signOut = createAsyncThunk('auth/signOut', async (): Promise<void> => authService.signOut());

const slice = createSlice({
  name: 'auth',
  initialState: { authErrored: false, user: null, userFarms: null } as IAuthState,
  reducers: {
    setUser: (state, action) => ({ ...state, user: action.payload })
  },
  extraReducers: (builder) => {
    builder.addCase(signInByCredentials.fulfilled, (state) => {
      state.authErrored = false;
    });
    builder.addCase(signInByCredentials.rejected, (state) => {
      state.authErrored = true;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      state.user = null;
    });
  }
});

export const { setUser } = slice.actions;
export const authReducer = slice.reducer;
export const getAuthErrored = (state: RootState) => state.auth.authErrored;
export const getUser = (state: RootState) => state.auth.user;
