import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { IListModel, ESuggestionModelType } from 'components/main/MainPageBreadcrumbs/BreadcrumbDropdownContent';
import { ITract } from 'models/tract';

import { useArrowNavigationListener } from 'hooks/util.hooks';

import { mfColors } from 'vars';

const DropdownEntries = styled.div`
  max-height: 450px;
  overflow: auto;
  margin: 0 -4px;
`;

const DropdownSubtitle = styled.div`
  font-size: 12px;
  padding: 6px 0;
  color: ${mfColors.grey};
`;

const DropdownSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${mfColors.grey};
  margin: 6px 0;
`;

const DropdownLink = styled(NavLink)`
  text-decoration: none;
  color: ${mfColors.darkGrey};
`;

const DropdownEntry = styled.button`
  min-width: 120px;
  width: 100%;
  padding: 6px 4px;
  font-size: 12px;
  font-weight: normal;
  display: block;
  color: ${mfColors.darkGrey};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 4px;
  border: 0;
  text-align: left;

  &:focus,
  &:active {
    outline: 0;
    background-color: ${mfColors.lightBlue};
    color: ${mfColors.white};
    font-family: MontserratBold;
  }
`;

interface IProps {
  linkPrefix: string;
  projects: IListModel[];
  tracts: IListModel[];
  onListEnd: (direction: -1 | 1) => void;
  onEntryClicked?: (entry: IListModel, type: ESuggestionModelType, e: React.MouseEvent<HTMLElement>) => void;
}

const SearchResultsDropdownContent = ({ projects, tracts, linkPrefix, onListEnd, onEntryClicked }: IProps): JSX.Element => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const handleArrowKeys = useArrowNavigationListener(containerRef, 'button', onListEnd);
  const { t } = useTranslation();

  return (
    <DropdownEntries onKeyDown={handleArrowKeys} ref={containerRef}>
      {!!projects.length && (
        <>
          <DropdownSubtitle>{t('shared.projects')}</DropdownSubtitle>
          {projects.map((model, index) => (
            <DropdownLink key={model.id} to={`${linkPrefix}/${model.id}`}>
              <DropdownEntry tabIndex={0} onClick={(e: React.MouseEvent<HTMLElement>) => onEntryClicked && onEntryClicked(model, ESuggestionModelType.Project, e)}>
                {model.name}
              </DropdownEntry>
            </DropdownLink>
          ))}
        </>
      )}
      {!!tracts.length && (
        <>
          {!!projects.length && <DropdownSeparator />}
          <DropdownSubtitle>{t('shared.tracts')}</DropdownSubtitle>
          {tracts.map((model, index) => (
            <DropdownLink key={model.id} to={`${linkPrefix}/${(model as any as ITract).project_ID}/${model.id}`}>
              <DropdownEntry tabIndex={0} onClick={(e: React.MouseEvent<HTMLElement>) => onEntryClicked && onEntryClicked(model, ESuggestionModelType.Tract, e)}>
                {model.name}
              </DropdownEntry>
            </DropdownLink>
          ))}
        </>
      )}
    </DropdownEntries>
  );
};

export default SearchResultsDropdownContent;
