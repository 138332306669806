import { useState, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import amplitude from 'amplitude-js';

import { IUser } from 'models/user';
import { TEventParams } from 'models/analytics';

import appConfig from 'config/config.json';

const UTM_PARAMS_STORAGE_KEY = 'utm_params';

interface IAnalyticsResult {
  logEvent: (event: TEventParams) => void;
  setUser: (user: IUser) => void;
}

export const useStoreUtmParams = (): void => {
  const [searchParams] = useSearchParams();
  const utmSource = searchParams.get('utm_source');
  const utmCampaign = searchParams.get('utm_campaign');

  useEffect(() => {
    if (utmSource || utmCampaign) {
      sessionStorage.setItem(UTM_PARAMS_STORAGE_KEY, JSON.stringify({ utmSource, utmCampaign }));
    }
  }, [utmSource, utmCampaign]);
};

export const useAnalytics = (): IAnalyticsResult => {
  const [client, setClient] = useState<amplitude.AmplitudeClient | null>(null);
  const [delayedEvents, setDelayedEvents] = useState<TEventParams[]>([]);
  let utmSource: string | null = null;
  let utmCampaign: string | null = null;

  try {
    const serializedUtmParams = sessionStorage.getItem(UTM_PARAMS_STORAGE_KEY);
    if (serializedUtmParams) {
      const utmParams = JSON.parse(serializedUtmParams);
      utmSource = utmParams.utmSource || null;
      utmCampaign = utmParams.utmCampaign || null;
    }
  } catch {
    utmSource = null;
    utmCampaign = null;
  }

  const getDecoratedEvent = useCallback(
    (event: TEventParams) => ({
      ...event,
      params: {
        utmCampaign,
        ...event.params
      }
    }),
    [utmCampaign]
  );

  useEffect(() => {
    if (!client) {
      const instance = amplitude.getInstance();
      instance.init(appConfig.amplitude.token);
      instance.onInit(() => {
        setClient(instance);
      });
    }
  }, [client]);

  useEffect(() => {
    if (client && delayedEvents.length) {
      delayedEvents.forEach((event) => {
        client.logEvent(event.type, getDecoratedEvent(event).params);
      });
    }
  }, [client, delayedEvents, getDecoratedEvent]);

  const logEvent = useCallback(
    (event: TEventParams) => {
      if (!client) {
        setDelayedEvents((prev) => [...prev, event]);
      } else {
        client.logEvent(event.type, getDecoratedEvent(event).params);
      }
    },
    [client, getDecoratedEvent]
  );

  const setUser = useCallback(
    (user: IUser) => {
      if (client) {
        client.setUserId(user.uid);
        client.setUserProperties({
          username: user.displayName || '',
          email: user.email,
          source: utmSource
        });
      }
    },
    [client, utmSource]
  );

  return {
    logEvent,
    setUser
  };
};
