import moment from 'moment';
import dateFormats from 'models/date';

const formatDayOfWeek = (date: string | number | Date): string => moment(date, 'MM/DD/YY').format(dateFormats.DATE_FORMAT_DDD_M_D);

const getMilliseconds = (date: string | Date): number => moment(date).valueOf();

const getSeconds = (date: string | Date): number => Math.floor(getMilliseconds(date) / 1000);

const formatDate = (date: string | number | Date, format: string): string => moment(date).format(format);

const getWeekDays = (locale: string, modifier?: (day: string) => string): string[] => {
  const baseDate = new Date(Date.UTC(2017, 0, 1));
  const weekDays: string[] = [];
  for (let i = 0; i < 7; i += 1) {
    weekDays.push(baseDate.toLocaleDateString(locale, { weekday: 'long' }));
    baseDate.setDate(baseDate.getDate() + 1);
  }
  return weekDays.map((day) => (modifier ? modifier(day) : day));
};

const dateFromNow = (date: string | Date | number) => moment(date).fromNow();

const splitDate = (date) => {
  const [year, mm, dd] = date.split('-');
  const mmToIndex = mm - 1;

  return new Date(year, mmToIndex, dd);
};

function sortByDate<T>(items: T[], dateKey: string, isDesc = true) {
  return items.slice().sort((a, b) => {
    const prevValue = a?.[dateKey].valueOf();
    const nextValue = b?.[dateKey].valueOf();

    return isDesc ? prevValue - nextValue : nextValue - prevValue;
  });
}

const getStartOfWeek = (date: number | Date): number => moment(date).startOf('week').valueOf();

const subtractDays = (date: number | Date, days: number): number => moment(date).subtract(days, 'days').valueOf();

const getYearDifference = (date: string) => moment().diff(moment(date), 'years');

const getMonthList = (locale = 'en') => {
  const months = [...Array(12).keys()].map((key) => new Date(0, key).toLocaleString(locale, { month: 'short' }));

  return months;
};

const dateUtils = {
  formatDayOfWeek,
  getSeconds,
  getMilliseconds,
  formatDate,
  getWeekDays,
  dateFromNow,
  splitDate,
  sortByDate,
  getStartOfWeek,
  subtractDays,
  getYearDifference,
  getMonthList
};

export default dateUtils;
