import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

interface IAppState {
  isSideBarExpanded: boolean;
  isUserMenuShown: boolean;
  isShowBIDashboard: boolean;
}
const slice = createSlice({
  name: 'mode',
  initialState: {
    isSideBarExpanded: true,
    isUserMenuShown: false,
    isShowBIDashboard: false
  } as IAppState,
  reducers: {
    setIsSideBarExpanded: (state, action) => ({ ...state, isSideBarExpanded: action.payload }),
    setIsUserMenuShown: (state, action) => ({ ...state, isUserMenuShown: action.payload }),
    setIsShowBIDashboard: (state, action) => ({ ...state, isShowBIDashboard: action.payload })
  }
});

export const { setIsSideBarExpanded, setIsUserMenuShown, setIsShowBIDashboard } = slice.actions;

export const isSideBarExpandedSelector = (state: RootState) => state.appState.isSideBarExpanded;
export const isUserMenuShownSelector = (state: RootState) => state.appState.isUserMenuShown;
export const isShowBIDashboardSelector = (state: RootState) => state.appState.isShowBIDashboard;
export const appStateReducer = slice.reducer;
