import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { ICustomer } from 'models/customer';
import { IProject } from 'models/project';
import { ITract } from 'models/tract';

import BreadcrumbDropdownContent, { ESuggestionModelType } from 'components/main/MainPageBreadcrumbs/BreadcrumbDropdownContent';

import Dropdown, { EToggleType } from 'atomicComponents/Dropdown';

import { mfColors } from 'vars';
import { uniqBy } from 'lodash';

const Wrapper = styled.div`
  font-size: 12px;
  line-height: 15px;
  display: inline-flex;
`;

const BreadcrumbLink = styled(NavLink)`
  text-decoration: none;
`;

const BreadcrumbEntry = styled.span`
  color: ${mfColors.lightBlue};
`;

const BreadcrumbSeparator = styled.span`
  color: ${mfColors.grey};
  margin: 0 6px;
`;

interface IProps {
  customer: ICustomer;
  project: IProject;
  tract: ITract | null;
  customers: ICustomer[];
  projects: IProject[];
  tracts: ITract[];
  prefix?: string;
}

const MainPageBreadcrumbs = ({ customer, project, tract, customers, projects, tracts, prefix = '/map' }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const projectsForSelect = useMemo(() => projects.map((entry) => ({ ...entry, name: entry.project_name })), [projects]);
  const tractsForSelect = useMemo(() => {
    const projectTracts = project ? tracts.filter((entry) => entry.project_ID === project.id) : tracts;
    const res = (tract ? projectTracts.filter((entry) => entry.id !== tract.id) : projectTracts).map((entry) => ({ ...entry, name: entry.business_unit_name }));

    return uniqBy(res, 'id');
  }, [tracts, project, tract]);

  return (
    <Wrapper>
      <BreadcrumbEntry>{customer.customer_name}</BreadcrumbEntry>
      <BreadcrumbSeparator>{'>'}</BreadcrumbSeparator>
      <Dropdown
        toggleType={EToggleType.Mouseover}
        control={
          project ? (
            <BreadcrumbLink to={`${prefix}/${project.id}`}>
              <BreadcrumbEntry>{project.project_name}</BreadcrumbEntry>
            </BreadcrumbLink>
          ) : (
            <BreadcrumbEntry>{t('shared.project')}</BreadcrumbEntry>
          )
        }
        content={<BreadcrumbDropdownContent modelType={ESuggestionModelType.Project} models={projectsForSelect} linkPrefix={`${prefix}/`} />}
      />
      {project && tractsForSelect.length > 0 && (
        <>
          <BreadcrumbSeparator>{'>'}</BreadcrumbSeparator>
          <Dropdown
            toggleType={EToggleType.Mouseover}
            control={tract ? <BreadcrumbEntry>{tract.business_unit_name}</BreadcrumbEntry> : <BreadcrumbEntry>{t('shared.tract')}</BreadcrumbEntry>}
            content={<BreadcrumbDropdownContent modelType={ESuggestionModelType.Tract} models={tractsForSelect} linkPrefix={`${prefix}/${project.id}/`} />}
          />
        </>
      )}
    </Wrapper>
  );
};

export default MainPageBreadcrumbs;
