import React, { useCallback } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp, IconProp } from '@fortawesome/fontawesome-svg-core';
import { mfColors } from '../vars';

interface IIconProps {
  disabled: boolean;
  active?: boolean;
  isSolid?: boolean;
}

const getIconColor = (props: IIconProps): string => {
  const activeIconColor = props.isSolid ? mfColors.white : mfColors.darkBlue;
  return props.active ? activeIconColor : mfColors.darkBlue;
};

const getBackgroundColor = (props: IIconProps): string => (props.isSolid && props.active ? mfColors.darkBlue : 'transparent');

const Tooltip = styled.div`
  visibility: hidden;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  left: 65px;
  height: 40px;
  z-index: 5;
  opacity: 0;
  transition: opacity 0.6s;
  transition-delay: 0.6s;
`;

const Wrapper = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${getBackgroundColor};
  color: ${getIconColor};
  cursor: ${({ disabled }: IIconProps) => (disabled ? 'default' : 'pointer')};
  &:hover {
    background-color: ${({ disabled }: IIconProps) => (disabled ? 'transparent' : 'rgba(49, 69, 106, 0.1)')};
    ${Tooltip} {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const Icon = styled(FontAwesomeIcon)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Triangle = styled.div`
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: -8px;
  width: 16px;
  height: 16px;
  border-radius: 0 0 0 6px;
  background-color: ${mfColors.darkBlue};
  transform: scaleX(1.5) rotate(45deg);
  z-index: -1;
`;

const TooltipBody = styled.div`
  height: 40px;
  padding: 0 20px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 38px;
  color: ${mfColors.white};
  background-color: ${mfColors.darkBlue};
`;

export enum EIconType {
  FontAwesome,
  Custom
}

interface IProps {
  icon: IconProp | JSX.Element;
  active: boolean;
  disabled?: boolean;
  tooltip?: string;
  iconSize?: string | number;
  iconType?: EIconType;
  isSolid?: boolean;
  onClick?: () => void;
}

const IconButton = ({ icon, active, iconSize, tooltip, iconType, disabled = false, isSolid = false, onClick }: IProps): JSX.Element => {
  const renderIcon = useCallback(
    (): JSX.Element => (iconType === EIconType.FontAwesome ? <Icon icon={icon as IconProp} size={iconSize as SizeProp} /> : (icon as JSX.Element)),
    [icon, iconSize, iconType]
  );

  return (
    <Wrapper onClick={() => !disabled && onClick && onClick()} disabled={disabled} active={active} isSolid={isSolid}>
      {renderIcon()}
      {tooltip && (
        <Tooltip>
          <Triangle />
          <TooltipBody>{tooltip}</TooltipBody>
        </Tooltip>
      )}
    </Wrapper>
  );
};

export default IconButton;
