import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { mfColors, mfSizes, mfShadows } from 'vars';
import { useDispatch, useSelector } from 'react-redux';
import { isUserMenuShownSelector, setIsUserMenuShown } from 'redux/appStateSlice';
import SideBarNav from 'components/shared/SideBarExpandedContent/SideBarExpandedContent';
import UserMenu from './UserMenu';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  min-width: ${mfSizes.staticMenuWidth};
  width: calc(${mfSizes.sideBarAreaWidth} - 8px);
  background-color: ${mfColors.sideBarBackground};
  position: relative;
  z-index: 2;
`;

const dynamicMenuVerticalPadding = 24;
const dynamicMenuLeftPadding = 16;

interface IDinamicMenuPartProps {
  background?: string;
}

const DynamicMenuPart = styled.div`
  height: 100%;
  flex-grow: 1;
  position: relative;
  background: ${({ background }: IDinamicMenuPartProps) => background || 'transparent'};

  &:before,
  &:after {
    content: '';
    position: absolute;
  }

  &:before {
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    height: 268px;
    width: 1px;
    background-color: ${({ background }: IDinamicMenuPartProps) => background || mfColors.sideBarBackground};
    opacity: 0.1;
  }

  &:after {
    z-index: 1;
    right: -8px;
    top: 0px;
    width: 8px;
    height: 100%;
    border-radius: 0 8px 8px 0;
    background-color: ${({ background }: IDinamicMenuPartProps) => background || mfColors.sideBarBackground};
    box-shadow: ${mfShadows.navBarShadow};
  }
`;

const DynamicMenuWrapper = styled.div`
  position: absolute;
  top: ${dynamicMenuVerticalPadding}px;
  left: ${dynamicMenuLeftPadding}px;
  width: calc(100% - 8px);
  height: calc(100% - ${dynamicMenuVerticalPadding * 2}px);
  padding: 0;
  z-index: 2;
  display: flex;
  flex-flow: column;
`;

type TProps = PropsWithChildren<{
  background?: string;
}>;

const SideBar = ({ background, children }: TProps) => {
  const isUserMenuShown = useSelector(isUserMenuShownSelector);
  const dispatch = useDispatch();

  const closeNavHandler = () => {
    dispatch(setIsUserMenuShown(false));
  };

  return (
    <Wrapper>
      {children && (
        <DynamicMenuPart background={background}>
          <DynamicMenuWrapper>{children}</DynamicMenuWrapper>
        </DynamicMenuPart>
      )}
      <SideBarNav isExpanded={isUserMenuShown} onClose={closeNavHandler}>
        <UserMenu />
      </SideBarNav>
    </Wrapper>
  );
};

export default SideBar;
