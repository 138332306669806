import React, { useMemo, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

import dateUtils from 'utils/date';
import dateFormats from 'models/date';

import { mfColors } from 'vars';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .alice-carousel {
    position: relative;
    height: 22px;
    padding: 0px 32px;

    .alice-carousel__prev-btn,
    .alice-carousel__next-btn {
      display: block;
      width: 22px;
      padding: 0;
      height: 22px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-weight: bold;
      font-size: 20px;
      text-align: center;
    }

    .alice-carousel__wrapper {
      position: relative;
      height: 22px;

      &::before {
        content: '';
        position: absolute;
        top: 4px;
        bottom: 4px;
        left: 0;
        right: 0;
        background: ${mfColors.timebarColor};
        border-radius: 16px;
      }
    }

    .alice-carousel__stage-item {
      padding: 0 16px !important;
      width: 100% !important;
    }

    .alice-carousel__stage {
      height: 14px;
      margin-top: 4px;
    }

    .alice-carousel__prev-btn {
      left: 0px;
    }

    .alice-carousel__next-btn {
      right: 0px;
    }
  }
`;

interface ITimeBarControlProps {
  isDisabled: boolean;
}

const TimeBarControl = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: ${({ isDisabled }: ITimeBarControlProps) => (isDisabled ? mfColors.timebarSatelliteColor : mfColors.timebarSurveyColor)};
  border-radius: 50%;

  &:hover {
    background-color: ${({ isDisabled }: ITimeBarControlProps) => (isDisabled ? 'transparent' : mfColors.superLightBlue)};
    cursor: ${({ isDisabled }: ITimeBarControlProps) => (isDisabled ? 'default' : 'pointer')};
  }
`;

const TimeBarContent = styled.div`
  position: relative;
  width: 100%;
  height: 14px;
`;

interface ITimeBarItemProps {
  isSelected: boolean;
}

const getTimeBarItemWidth = ({ isSelected }: ITimeBarItemProps): string => (isSelected ? '6px' : '3px');

const getTimeBarItemColor = ({ isSelected }: ITimeBarItemProps): string => (isSelected ? mfColors.blue : mfColors.timebarSurveyColor);

const TimeBarItem = styled.div`
  position: absolute;
  height: ${({ isSelected }: ITimeBarItemProps) => (isSelected ? '22px' : '100%')};
  top: ${({ isSelected }: ITimeBarItemProps) => (isSelected ? '-4px' : '0px')};
  background-color: ${getTimeBarItemColor};
  width: ${getTimeBarItemWidth};
  border-radius: 3px;
  transform: translateX(-50%);

  &:hover {
    width: 3px;
    height: 18px;
    top: -2px;
    border-radius: 3px;
    background: ${mfColors.blue};
    cursor: pointer;
  }
`;

const Title = styled.div`
  margin: 8px 0 4px;
  font-family: MontserratBold;
  font-size: 16px;
`;

const Subtitle = styled.div`
  color: ${mfColors.grey};
  font-size: 12px;
`;

const ITEMS_PER_SLIDE = 4;

interface ITimelineItem<T> {
  id: string;
  isSelected: boolean;
  timestamp: number;
  model: T;
}

interface IProps<T> {
  selectedSurvey: string;
  surveys: T[];
  onSelectSurvey: (survey: T) => void;
}

const SurveyTimeline = <T extends { id: string; surveyName: string; startDate: string; endDate: string }>({
  selectedSurvey,
  surveys,
  onSelectSurvey
}: IProps<T>): JSX.Element | null => {
  const carouselRef = useRef<AliceCarousel | null>(null);

  const timelineItems = useMemo(() => {
    const result: ITimelineItem<T>[] = surveys.map((survey) => ({
      id: survey.id,
      isSelected: selectedSurvey === survey.id,
      timestamp: dateUtils.getMilliseconds(survey.startDate),
      model: survey
    }));

    return result.sort((a, b) => a.timestamp - b.timestamp);
  }, [surveys, selectedSurvey]);

  const itemsSlices = useMemo(() => {
    const result: ITimelineItem<T>[][] = [];

    for (let i = timelineItems.length; i > 0; i -= ITEMS_PER_SLIDE) {
      const startIndex = i - ITEMS_PER_SLIDE >= 0 ? i - ITEMS_PER_SLIDE : 0;
      result.push(timelineItems.slice(startIndex, i));
    }
    return result.reverse();
  }, [timelineItems]);

  useEffect(() => {
    if (itemsSlices.length > 0) {
      const selectedSlice = itemsSlices.findIndex((items) => !!items.find((item) => item.isSelected));
      if (selectedSlice !== -1) {
        setTimeout(() => {
          if (carouselRef.current) {
            carouselRef.current.slideTo(selectedSlice);
          }
        }, 0);
      }
    }
  }, [itemsSlices, carouselRef]);

  const currentSurveyInfo = useMemo(() => {
    const survey = surveys?.find((survey) => survey.id === selectedSurvey);
    if (!survey || !surveys) return null;
    const surveyDates = dateUtils.formatDate(survey.startDate, dateFormats.DATE_FORMAT_DD_MMM_YYYY);

    return (
      <>
        <Title>{surveyDates}</Title>
        <Subtitle>{survey.surveyName || ''}</Subtitle>
      </>
    );
  }, [selectedSurvey, surveys]);

  const handleSelectItem = useCallback(
    (item: ITimelineItem<T>) => {
      onSelectSurvey && onSelectSurvey(item.model);
    },
    [onSelectSurvey]
  );

  const items = useMemo(() => {
    const slides = itemsSlices.map((slice, index) => (
      <TimeBarContent key={index}>
        {slice.map((item, index) => {
          const itemsOffset = slice.length > 2 && slice.length < ITEMS_PER_SLIDE ? 100 / slice.length - 1 : 33;
          const currentItemOffset = index * itemsOffset;
          const style: { [key: string]: string } = {
            left: `${currentItemOffset}%`
          };

          return <TimeBarItem key={item.id} onClick={() => handleSelectItem(item)} isSelected={item.isSelected} style={style} />;
        })}
      </TimeBarContent>
    ));
    return slides;
  }, [itemsSlices, handleSelectItem]);

  const isPrevDisabled = useMemo(() => {
    const selectedSlice = itemsSlices.find((slice: ITimelineItem<T>[]) => !!slice.find((item) => item.isSelected));
    return !selectedSlice || (itemsSlices.indexOf(selectedSlice) === 0 && selectedSlice.findIndex((item) => item.isSelected) === 0);
  }, [itemsSlices]);

  const isNextDisabled = useMemo(() => {
    const selectedSlice = itemsSlices.find((slice: ITimelineItem<T>[]) => !!slice.find((item) => item.isSelected));
    return !selectedSlice || (itemsSlices.indexOf(selectedSlice) === itemsSlices.length - 1 && selectedSlice.findIndex((item) => item.isSelected) === selectedSlice.length - 1);
  }, [itemsSlices]);

  const interceptSlideChange = useCallback(
    (event, direction: 'prev' | 'next') => {
      const selectedSlice = itemsSlices.find((slice: ITimelineItem<T>[]) => !!slice.find((item) => item.isSelected));
      if (selectedSlice) {
        const selectedItemIndex = selectedSlice.findIndex((item) => item.isSelected);
        if (selectedItemIndex !== -1) {
          if (direction === 'prev' && selectedItemIndex > 0) {
            handleSelectItem(selectedSlice[selectedItemIndex - 1]);
            event.stopPropagation();
          } else if (direction === 'next' && selectedItemIndex < selectedSlice.length - 1) {
            handleSelectItem(selectedSlice[selectedItemIndex + 1]);
            event.stopPropagation();
          } else if (direction === 'prev') {
            const prevSliceIndex = itemsSlices.indexOf(selectedSlice) - 1;
            if (prevSliceIndex >= 0) {
              const prevSlice = itemsSlices[prevSliceIndex];
              if (prevSlice.length > 0) {
                handleSelectItem(prevSlice[prevSlice.length - 1]);
              }
            }
          } else if (direction === 'next') {
            const nextSliceIndex = itemsSlices.indexOf(selectedSlice) + 1;
            if (nextSliceIndex < itemsSlices.length) {
              const nextSlice = itemsSlices[nextSliceIndex];
              if (nextSlice.length > 0) {
                handleSelectItem(nextSlice[0]);
              }
            }
          }
        }
      }
    },
    [itemsSlices, handleSelectItem]
  );

  return (
    <Wrapper>
      <AliceCarousel
        renderPrevButton={() => (
          <TimeBarControl onClick={(event) => interceptSlideChange(event, 'prev')} isDisabled={isPrevDisabled}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </TimeBarControl>
        )}
        renderNextButton={() => (
          <TimeBarControl onClick={(event) => interceptSlideChange(event, 'next')} isDisabled={isNextDisabled}>
            <FontAwesomeIcon icon={faChevronRight} />
          </TimeBarControl>
        )}
        ref={carouselRef}
        disableDotsControls
        disableButtonsControls={timelineItems.length === 1}
      >
        {items}
      </AliceCarousel>
      {currentSurveyInfo}
    </Wrapper>
  );
};

export default SurveyTimeline;
