import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import { mfColors } from 'vars';

const Wrapper = styled.div`
  display: flex;
  height: 1000%;
  overflow: auto;
`;

const Gallery = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
`;

const GalleryPreviews = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GalleryPreview = styled.img`
  width: 100px;
  height: 100px;
  margin: 0 4px;
  border-radius: 8px;
  cursor: pointer;
  padding: 0px;
  border: 2px solid ${mfColors.white};
  background: ${mfColors.white};
`;

interface IProps {
  images: any[];
  onImageClick: (image: string) => void;
}

const ImageGallery = ({ images, onImageClick }: IProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const imageUrls = useMemo(() => {
    const result = images.map((image) => ({
      url: image.url,
      previewUrl: image.url
    }));

    return result;
  }, [images]);

  const onFallback = (e, fallbackImage: string) => {
    e.target.src = fallbackImage;
  };

  return (
    <Wrapper ref={wrapperRef}>
      <Gallery>
        <GalleryPreviews>
          {imageUrls.map((image) => (
            <GalleryPreview
              onClick={() => {
                onImageClick(image.url);
              }}
              src={image.previewUrl}
              alt=""
              onError={(e) => onFallback(e, image.url)}
            />
          ))}
        </GalleryPreviews>
      </Gallery>
    </Wrapper>
  );
};

export default ImageGallery;
