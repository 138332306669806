import { useCallback } from 'react';
import { collection, doc, getDoc } from 'firebase/firestore';
import db from 'services/data/providers/firebase';
import { ICustomer } from 'models/customer';
import useLogger, { EStorageType } from 'hooks/logger.hooks';

export const useFetchCustomer = (): ((customerID: string) => Promise<ICustomer | null>) => {
  const { logError } = useLogger();

  return useCallback(
    async (customerID): Promise<ICustomer | null> => {
      const path = '/carbon-customers';
      const customerCollection = collection(db.firestore, path);
      const customerDoc = doc(customerCollection, customerID);

      try {
        return await getDoc(customerDoc).then((doc) => {
          if (!doc.exists()) return null;
          return {
            ...doc.data(),
            id: doc.id
          } as ICustomer;
        });
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
        return Promise.resolve(null);
      }
    },
    [logError]
  );
};

const customersService = {
  useFetchCustomer
};

export default customersService;
