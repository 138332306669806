import { useCallback } from 'react';
import { collection, getDocs, QueryConstraint, where, query } from 'firebase/firestore';
import db from 'services/data/providers/firebase';
import { ISurvey } from 'models/survey';
import useLogger, { EStorageType } from 'hooks/logger.hooks';

export const useFetchSurveys = (): ((projectID: string) => Promise<ISurvey[]>) => {
  const { logError } = useLogger();

  return useCallback(
    async (projectID): Promise<ISurvey[]> => {
      const surveysCollection = collection(db.firestore, '/carbon-surveys');
      const queryParams = [] as QueryConstraint[];
      queryParams.push(where('project_ID', '==', projectID));

      try {
        const q = query(surveysCollection, ...queryParams);
        const res = await getDocs(q).then((docs) => {
          const combinations: ISurvey[] = [];
          docs.forEach((doc) => {
            combinations.push({
              id: doc.id,
              ...doc.data()
            } as ISurvey);
          });
          return combinations;
        });
        return res;
      } catch (e) {
        logError(e as Error, '/carbon-surveys', EStorageType.Firestore);
        return Promise.resolve([]);
      }
    },
    [logError]
  );
};

const surveysService = {
  useFetchSurveys
};

export default surveysService;
