import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { mfColors } from 'vars';

const Wrapper = styled.div`
  color: ${mfColors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: MontserratBold;
  font-size: 14px;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 8px;

  .colors {
    display: flex;
    margin: 0 8px;
    border: 2px solid ${mfColors.darkGrey};
  }

  .color {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${mfColors.white};
    font-size: 12px;
  }

  .separator {
    height: 40px;
    width: 2px;
    background-color: ${mfColors.darkGrey};
  }
`;

const TCO2ELegend = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Legend>
        <span>{t('carbon.low')}</span>
        <div className="colors">
          <div style={{ background: '#A02C2D' }} className="color">
            <span>&lt;15</span>
          </div>
          <div className="separator" />
          <div style={{ background: '#C7534F' }} className="color">
            <span>15-27</span>
          </div>
          <div className="separator" />
          <div style={{ background: '#CD7F27' }} className="color">
            <span>27-52</span>
          </div>
          <div className="separator" />
          <div style={{ background: '#D5B02D' }} className="color">
            <span>52-71</span>
          </div>
          <div className="separator" />
          <div style={{ background: '#A2BD42' }} className="color">
            <span>71-93</span>
          </div>
          <div className="separator" />
          <div style={{ background: '#76A747' }} className="color">
            <span>&gt;93</span>
          </div>
        </div>
        <span>{t('carbon.high')}</span>
      </Legend>
      <div>{t('carbon.tCO2e_acre')}</div>
    </Wrapper>
  );
};

export default TCO2ELegend;
